import { FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { AppState } from '../../app/reducers';
import { AppRoutes } from '../../helpers';
import { ParticipationHOC } from '../../hoc/ParticipationHOC';
import { TiresCartHOC } from '../../hoc/TiresCartHOC';
import { EditUserPage } from '../EditUserPage/EditUserPage';
import { Navbar } from '../Navbar/Navbar';
import { MobileHeader } from '../Navbar/NavbarMobile';
import { ProfileForm } from '../ProfileForm/ProfileForm';
import { MainPage } from './MainPage';
import styles from './Main.module.scss';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { eventsFetch } from '../../app/reducers/event/event.actions';
import { getUserFetch } from '../../app/reducers/user/user.actions';
import SubmittedApplications from '../SubmittedApplications/SubmittedApplications';
import PayCashless from '../PayCashless/PayCashless';
import { ModalWindow } from '../../elements/ModalWindow/ModalWindow';
import { CircularProgress } from '@material-ui/core';
import { TiresPage } from '../TiresPage/TiresPage';

export const Main: FC = () => {
  const { is_profile_exist, isAuthorized, id } = useSelector((state: AppState) => state.userStore);

  const [expanded, setExpanded] = useState<boolean>(false);

  const history = useHistory();
  const header = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (is_profile_exist && isAuthorized && id) {
      dispatch(eventsFetch());
    }
  }, [dispatch, is_profile_exist, isAuthorized, id]);

  useEffect(() => {
    if (isAuthorized && !id) {
      dispatch(getUserFetch());
    }
  }, [dispatch, is_profile_exist, isAuthorized, id]);

  useEffect(() => {
    if (expanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [expanded]);

  let [modalWindow, setModalWindow] = useState<boolean>(false);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setExpanded(false);
      setModalWindow(history.location.hash === '#success');
    });
    return () => {
      unlisten();
    };
  }, []);

  const handleExpandMobileMenu = () => {
    setExpanded(!expanded);
  };

  const handleClickOutside = (e: MouseEvent<Document>) => {
    if (header.current?.contains(e.target as Node)) return;
    setExpanded(false);
  };

  const handleClose = () => {
    history.push(AppRoutes.main);
    setModalWindow(false);
  };

  if (!is_profile_exist && isAuthorized && id) return <ProfileForm />;
  else if (is_profile_exist && isAuthorized && id)
    return (
      <>
        <MobileHeader ref={header} action={handleExpandMobileMenu} expanded={expanded} />
        <Navbar handleClickOutside={handleClickOutside} expanded={expanded} />
        <Route exact path={AppRoutes.main} component={MainPage} />
        <Route path={AppRoutes.event} component={ParticipationHOC} />
        <Route path={AppRoutes.editUser} component={EditUserPage} />
        <Route path={AppRoutes.orders} component={SubmittedApplications} />
        <Route path={AppRoutes.cashless} component={PayCashless} />
        <Route path={AppRoutes.tires} component={TiresPage} />
        <Route path={AppRoutes.tiresCart} component={TiresCartHOC} />
        <div className={`${styles.navBackground} ${expanded && styles.expanded}`}></div>
        <ModalWindow handleClose={handleClose} open={modalWindow}></ModalWindow>
      </>
    );
  else return <CircularProgress />;
};
