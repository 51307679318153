import {GET_ORDERS, OrderActions} from "./order.types";


export interface SubmmitedOrders {
    id?: number | null;
    event_name?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    status?: string | null;
    event_location?: string | null;
    logo?: any | null;
    event_order_group: event_order_group;
}

export interface event_order_group {
    pilot_common_class: string | null;
    pilot_special_class: string | null;
    price: number | bigint;
    additional_services: additional_services[];
    events: events[];
}

export interface additional_services {
    id?: number | null;
    name?: string | null;
}

export interface events {
    date?: string | null;
    has_training_photo?: boolean | null;
    has_race_photo?: boolean | null;
    price: number | null;
}

export interface OrderState {
    SubmmitedOrders: SubmmitedOrders[],

}

const initialState: OrderState ={
    SubmmitedOrders: []
}

export const OrderReducer = (state: OrderState = initialState, action: OrderActions) => {
    switch (action.type) {
        case GET_ORDERS: {
            return {
                ...state,
                SubmmitedOrders: action.order
            };
        }
        default:
            return state;
    }
}
