import styles from './Participation.module.scss';
import {
  FC,
  useRef,
  useState,
  MouseEvent,
  ComponentPropsWithoutRef,
  Fragment,
  useEffect,
} from 'react';
import { ReactComponent as ArrowExpand } from '../../assets/icons/arrow_up.svg';
import { ReactComponent as DoubleArrow } from '../../assets/icons/double_arrows.svg';
import { ReactComponent as Check } from '../../assets/icons/check_24px.svg';
import { Checkbox, CheckboxGroup, RadioButton, TextButton } from '../../elements';
import { Calculator, PaymentTypes } from './Calculator';
import { CircularProgress, ClickAwayListener } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { AppRoutes, formatDDMM, getStartEndDate } from '../../helpers';
import { eventsFetch, setCurrentEvent } from '../../app/reducers/event/event.actions';
import { IEvent, IEventDays } from '../../app/reducers/event/event.reducer';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { sortById } from '../../helpers/sort';
import { orderApi } from '../../api/order.api';
import { CustomizedDialogs } from '../../elements/Success/Success';
import { setError } from '../../app/reducers/error/error.actions';

enum PilotClassNames {
  special = 'Особенные классы',
}

export interface IFromEvent {
  event_day: number | null;
}
export interface IFormPhoto {
  event_day?: number | null;
  has_training_photo: boolean;
  has_race_photo: boolean;
}

export interface IOrderFormData {
  additional_services?: number[];
  event: number;
  events?: IFromEvent[];
  photo?: IFormPhoto[];
  pilot_common_class?: number | null;
  pilot_special_class?: number | null;
  price?: string | null;
  paymentType: string;
}

type SelectInnerProps = {
  propStyles?: string | null;
  event: IEvent;
} & ComponentPropsWithoutRef<'button'>;

const SelectInner: FC<SelectInnerProps> = (props) => {
  const event = props.event;
  const dates = getStartEndDate(event.event_days);
  const startDate = formatDDMM(dates.start_date);
  const endDate = formatDDMM(dates.end_date);
  const dispatch = useDispatch();

  const handleSelectInnerClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setCurrentEvent(event.id));
  };

  return (
    <button
      disabled={props.event.has_order}
      type="button"
      onClick={handleSelectInnerClick}
      className={`${styles.selectInner} ${props.propStyles}`}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={event.logo} alt="Name" />
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.titleContainer}>
          <h3>{event.location}</h3>
          <p>{event.name}</p>
        </div>
        <div className={styles.date}>
          <span>
            {startDate} - {endDate}
          </span>
          {props.event.has_order && (
            <span className={styles.hasOrder}>
              <Check className={styles.checkIcon} />
              Заявка подана
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

export interface IPaymentType {
  online: boolean;
  onspot: boolean;
}

export const Participation: FC = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const history = useHistory();
  const expand = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const { currentEvent, events, isFetching } = useSelector((store: AppState) => store.eventStore);

  useEffect(() => {
    const hasOrders = events.filter((event) => event.has_order === false);
    if (hasOrders.length === 0) history.push(AppRoutes.main);
    console.log(hasOrders);
  }, []);

  const currentEventArray = events.find((event: IEvent) => event.id === currentEvent) || events[0];
  const raceClasses = Object.keys(currentEventArray.pilot_classes);

  // const specialPrices = currentEventArray.event_days.map((day: IEventDays) =>
  //   parseInt(day.special_price),
  // );
  // const commonPrices = currentEventArray.event_days.map((day: IEventDays) =>
  //   parseInt(day.common_price),
  // );
  // const minSpecialPrice = Math.min(...specialPrices);
  // const minCommonPrice = Math.min(...commonPrices);

  const isPhotoSessionAvaible: boolean = currentEventArray.event_days.some(
    (day: IEventDays) => day.photo_race_price || day.photo_training_price,
  );

  const initialFormValues: IOrderFormData = {
    event: currentEventArray.id,
    events: [
      {
        event_day: currentEventArray.event_days[0].id || null,
      },
    ],
    photo: currentEventArray.event_days.map((day) => ({
      has_training_photo: false,
      has_race_photo: false,
    })),
    paymentType: PaymentTypes.online,
  };

  const handleExpand = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const handleClickOutside = (e: MouseEvent<Document>) => {
    if (expand.current?.contains(e.target as Node)) return;
    setExpanded(false);
  };

  const handleBackButtonClick = () => {
    history.push(AppRoutes.main);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: IOrderFormData) => {
    if (!values.pilot_common_class && !values.pilot_special_class) {
      dispatch(setError(['Укажите класс гонок для участия']));
      window.scrollTo({ top: 0 });
      return;
    }
    try {
      const sortedDays = sortById(values.events);
      const sortedPhotos = sortById(values.photo);

      const mappedEvents = sortedDays.map((day: any, index: number) => ({
        ...day,
        ...sortedPhotos[index],
      }));

      const filteredEvents = mappedEvents.filter((event: any) => event.event_day);

      if (!filteredEvents || filteredEvents.length < 1) {
        dispatch(setError(['Укажите день участия']));
        window.scrollTo({ top: 0 });
        return;
      }

      const price = filteredEvents.reduce((a: number, event: any) => {
        const day = currentEventArray.event_days.find((day) => day.id === event.event_day);
        const dayCommonPrice = values.pilot_common_class ? Number(day?.common_price) : 0;
        const daySpecialPrice = values.pilot_special_class ? Number(day?.special_price) : 0;
        return a + (dayCommonPrice + daySpecialPrice);
      }, 0);

      const reqData = {
        event: values.event,
        pilot_common_class: values.pilot_common_class,
        pilot_special_class: values.pilot_special_class,
        additional_services: values.additional_services,
        events: filteredEvents,
        price,
      };
      const windowRef = window.open();

      const { paymentType } = values;

      if (paymentType === PaymentTypes.onSpot && windowRef) {
        windowRef.close();
      }

      const res = await orderApi.getOrder(reqData);

      if (res.invoice && res.invoice.invoice_url && windowRef) {
        if (paymentType === PaymentTypes.online) {
          windowRef.location = res.invoice.invoice_url;
        }
        if (paymentType === PaymentTypes.cashless) {
          windowRef.location.href = `https://app.motoring.ru${AppRoutes.cashless}?idOrder=${res.id}`;
        }
        handleOpen();
        dispatch(eventsFetch());
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      render={({ handleSubmit, form }) => (
        <form className={styles.participationContainer} onSubmit={handleSubmit}>
          <div className={styles.participation}>
            <TextButton onClick={handleBackButtonClick} variant="witharrow">
              Назад
            </TextButton>
            <h2 className={styles.formTitle}>Подать заявку на участие</h2>
            {isFetching ? (
              <CircularProgress />
            ) : (
              <div className={styles.form}>
                <h3 className={styles.optionTitle}>Мероприятие</h3>
                {events && (
                  <ClickAwayListener
                    mouseEvent="onMouseUp"
                    touchEvent="onTouchEnd"
                    onClickAway={handleClickOutside}>
                    <div onClick={handleExpand} className={styles.expand}>
                      <SelectInner
                        disabled
                        propStyles={expanded ? styles.isExpanded : null}
                        event={currentEventArray}
                      />
                      <ArrowExpand
                        className={`${styles.expandArrow} ${expanded && styles.arrowExpanded}`}
                      />
                      {expanded && (
                        <div ref={expand} className={styles.expandMenu}>
                          {events &&
                            events.map((event) => (
                              <SelectInner event={event} key={'InnerSelect' + event.id} />
                            ))}
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                )}
                <Field name="id" type="hidden" component="input" value={currentEventArray.id} />
                <div className={styles.block}>
                  <h3 className={styles.optionTitle}>Даты участия</h3>
                  <div className={styles.buttonsBlock}>
                    {currentEventArray &&
                      currentEventArray.event_days.map((day: IEventDays, index) => {
                        const date = formatDDMM(day.date);
                        return (
                          <Field
                            name={`events[${index}].event_day`}
                            key={`day${day.id}`}
                            parse={(v) => (v === true ? day.id : null)}
                            type="checkbox">
                            {(props) => (
                              <Checkbox {...props.input}>
                                <span>{date}</span>
                                <span>
                                  до{' '}
                                  {Math.max(
                                    parseInt(day.common_price),
                                    parseInt(day.special_price),
                                  )}{' '}
                                  ₽
                                </span>
                              </Checkbox>
                            )}
                          </Field>
                        );
                      })}
                  </div>
                  <h3 className={`${styles.optionTitle} ${styles.withBorderTop}`}>
                    Классы гонок для участия
                  </h3>
                  <div className={styles.raceClassesBlock}>
                    {raceClasses &&
                      raceClasses.map((raceClass, index) => {
                        return (
                          <Fragment key={'RaceClass' + index}>
                            <span className={styles.subtitle}>{raceClass}</span>
                            <div className={styles.buttonsBlock}>
                              {currentEventArray.pilot_classes[raceClass] &&
                                currentEventArray.pilot_classes[raceClass].map((item) => {
                                  const fieldName =
                                    item.type === PilotClassNames.special
                                      ? 'pilot_special_class'
                                      : 'pilot_common_class';
                                  return (
                                    <Field
                                      type="radio"
                                      parse={(v) => Number(v)}
                                      value={item.id}
                                      key={item.name + item.id}
                                      name={fieldName}>
                                      {(props) => (
                                        <RadioButton {...props.input}>
                                          <span>{item.name}</span>
                                          <span>
                                            {/* {item.type === PilotClassNames.special
                                              ? minSpecialPrice
                                              : minCommonPrice}{' '}
                                            ₽ */}
                                          </span>
                                        </RadioButton>
                                      )}
                                    </Field>
                                  );
                                })}
                            </div>
                          </Fragment>
                        );
                      })}
                    <TextButton
                      type="reset"
                      style={{ marginLeft: 'auto' }}
                      onClick={() => {
                        form.change('pilot_special_class', null);
                        form.change('pilot_common_class', null);
                      }}>
                      Очистить классы
                    </TextButton>
                  </div>
                  {currentEventArray.additional_services && (
                    <>
                      <h3 className={styles.optionTitle}>Услуги</h3>
                      <span className={styles.subtitle}>Оплачивается отдельно на мероприятии.</span>
                      {currentEventArray.additional_services.map((service) => {
                        return (
                          <Field
                            name="additional_services"
                            key={'Service' + service.id}
                            type="checkbox"
                            value={service.id}>
                            {(props) => (
                              <Checkbox {...props.input} propStyles={styles.serviceCheckbox}>
                                <p>{service.name}</p>
                              </Checkbox>
                            )}
                          </Field>
                        );
                      })}
                    </>
                  )}
                  {isPhotoSessionAvaible && (
                    <>
                      <h3 className={`${styles.optionTitle} ${styles.withBorderTop}`}>
                        Фотосессия
                      </h3>
                      <span className={styles.subtitle}>
                        Выберите дни и время для фотосессии. Оплачивается отдельно на мероприятии.
                      </span>
                    </>
                  )}
                  {currentEventArray &&
                    currentEventArray.event_days.map((day: IEventDays, index: number) => {
                      if (day.photo_race_price || day.photo_training_price)
                        return (
                          <CheckboxGroup key={'Photosessions' + day.id} day={day} index={index} />
                        );
                    })}
                  <span className={styles.footerForward}>
                    Ознакомьтесь с итоговой ценой и выберите способ оплаты{' '}
                    <DoubleArrow className={styles.doubleArrow} />
                  </span>
                </div>
              </div>
            )}
          </div>
          <Calculator event={currentEventArray} />
          <CustomizedDialogs handleClose={handleClose} open={open} />
        </form>
      )}
    />
  );
};
