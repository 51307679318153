import api from "./axios.main";


enum orderRoutes {
  order = "orders/",
  bill = "/bill/"
}

export const orderApi = {
  getOrder(data: any) {
    return api.post(orderRoutes.order, data).then((res) => res.data);
  },
  downloadBill(id: number) {
    return api.get(orderRoutes.order + `${id}` + orderRoutes.bill, {responseType: 'arraybuffer'}).then((res) =>  res.data);
  }
};
