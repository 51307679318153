import axios from 'axios';

const token = '7144254508448d7c757923ff7fcdcd849b70f1db'

const suggest = axios.create({
  baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Authorization": "Token " + token
},
});

export const suggestApi = {
  getData(query: string) {
    return suggest.get(`?query=${query}&from_bound`).then((res) => res.data);
  },
  postData(query: string) {
    return suggest.post('', { query, from_bound: { value: 'city' }, to_bound: { value: 'city' } }).then(res => res.data)
  }
};
