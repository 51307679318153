import { ThunkAction, Action, applyMiddleware, createStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunkMiddleware];
const middlewareEnchancer = applyMiddleware(...middlewares);

export const store = createStore(rootReducer, composeWithDevTools(middlewareEnchancer));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
