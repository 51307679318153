import { FC, MouseEvent, useState } from 'react';
import styles from './Navbar.module.scss';
import { ReactComponent as LogoMain } from '../../assets/motoringLogo.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Apps } from '../../assets/icons/applications.svg';
import { ReactComponent as Podium } from '../../assets/icons/podium.svg';
// import { ReactComponent as Wheel } from '../../assets/icons/wheel.svg';
import { ReactComponent as Dots } from '../../assets/icons/dots.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/log_out.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Wheel } from '../../assets/icons/wheel.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../elements';
import { Avatar, ClickAwayListener, IconButton, Menu, MenuItem } from '@material-ui/core';
import { AppRoutes } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { clearUser } from '../../app/reducers/user/user.actions';

type Props = {
  expanded?: boolean;
  handleClickOutside: (e: MouseEvent<Document>) => void,
};

export const Navbar: FC<Props> = ({ expanded, handleClickOutside }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { first_name, last_name, photo } = useSelector((store: AppState) => store.userStore);
  const {currentEvent} = useSelector((store: AppState) => store.eventStore);
  const tiresCart = useSelector((store: AppState) => store.tiresStore.cart);
  const eventButtonDisabled: boolean = !Boolean(currentEvent);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditUserButton = () => {
    history.push(AppRoutes.editUser);
    handleClose();
  };
  const handleLogOutButton = () => {
    dispatch(clearUser());
  };

  const handleOrderClick = () => {
    history.push(AppRoutes.event);
  };

  return (
    <ClickAwayListener mouseEvent="onMouseUp" touchEvent="onTouchEnd" onClickAway={handleClickOutside}>
    <div className={`${styles.container} ${expanded && styles.expanded}`}>
      <Link to={AppRoutes.main} className={styles.logoContainer}>
        <LogoMain className={styles.logo} />
      </Link>
      <nav className={styles.navContainer}>
        <Link className={`${styles.navLink} ${(location.pathname === AppRoutes.main) && styles.active}`} to={AppRoutes.main}>
          <Home className={styles.navIcon} /> Главная
        </Link>
        <a className={styles.navLink} href="https://motoring.ru/results/" target="_blank">
          <Podium className={styles.navIcon} />
          Результаты гонок
        </a>
        <Link className={`${styles.navLink} ${(location.pathname === AppRoutes.orders) && styles.active}`} to={AppRoutes.orders}>
          <Apps className={styles.navIcon} /> Поданные заявки
        </Link>
        <Link className={`${styles.navLink} ${(location.pathname === AppRoutes.tires) && styles.active} ${styles.withBadge}`} to={AppRoutes.tires}>
          <div className={styles.navLabelBlock}>
            <Wheel className={styles.navIcon} /> Заказ покрышек
          </div>
          {
            tiresCart.length > 0 && (
              <div className={styles.cartItemsBadge}>{tiresCart.length}</div>
            )
          }
        </Link>
        {/* <button disabled className={`${styles.mobileButtons} ${styles.navLink}`}>
          <Edit className={styles.navIcon} />
          Редактировать профиль
        </button> */}
        <button onClick={handleLogOutButton} className={`${styles.mobileButtons} ${styles.navLink}`}>
          <LogoutIcon className={styles.navIcon} />
          Выйти
        </button>
        {/* <a
          className={styles.navLink}
          target="_blank"
          href="https://motoring.ru/wp-content/uploads/2021/06/photo_2021-06-23_00-26-45.jpg">
          <Wheel className={styles.navIcon} />
          Заказ покрышек
        </a> */}
      </nav>
      <div className={styles.navFooter}>
        <span className={styles.navFooterText}>Узнай, на что ты способен на гоночной трассе!</span>
        <Button styles={styles.eventButton} disabled={eventButtonDisabled} onClick={handleOrderClick}>Подать заявку</Button>
        <div className={styles.userContainer}>
          <Avatar className={styles.userAvatar} src={photo ? photo : undefined}/>
          <strong className={styles.userName}>{`${first_name} ${last_name}`}</strong>
          <IconButton
            onClick={handleClick}
            aria-controls="user-menu"
            aria-haspopup="true"
            className={styles.userDots}
            size="small">
            <Dots />
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem className={styles.menuItem} onClick={handleEditUserButton}>
              <Edit className={styles.menuItemIcon} />
              Редактировать профиль
            </MenuItem>
            <MenuItem className={styles.menuItem} onClick={handleLogOutButton}>
              <LogoutIcon className={styles.menuItemIcon} />
              Выход
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
    </ClickAwayListener>
  );
};
