import { useEffect } from 'react';
import styles from './SubmittedApplications.module.scss';
import Applications from './Applications/Applications';
import Wheels from './Wheels/Wheels';
import { currentOrderApi } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from '../../app/reducers/order/order.actions';
import { AppState } from '../../app/reducers';
import { Button } from '../../elements';

const SubmittedApplications = () => {
    const dispatch = useDispatch();
    const order = useSelector((state: AppState) => state.orderStore);

    useEffect(() => {
        currentOrderApi
            .getOrder()
            .then((data) => {
                dispatch(getOrder(data));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className={styles.main}>
            <div className={styles.sub_app}>
                <p className={styles.text}>Поданные заявки</p>
              {order.SubmmitedOrders.length > 0 ?
                <>
                  {order.SubmmitedOrders.map((currentOrder: any, index: number) => (
                      <Applications
                        index={index + 1}
                        event_name={currentOrder.event_name}
                        start_date={currentOrder.start_date}
                        event_order_group={currentOrder.event_order_group}
                        key={`${currentOrder.id}`}
                        id={currentOrder.id}
                        event_location={currentOrder.event_location}
                        logo={currentOrder.logo}
                        payment_info={currentOrder?.payment_info}
                        invoice_link={currentOrder?.invoice_link}
                      />
                    ))}
                  </>
                  :
                <p className={styles.noData}>Поданных заявок пока нет.</p>
              }
            </div>

            {/*<div className={styles.wheels}>*/}
            {/*    <p className={styles.text_wheel}>Предложения покрышек</p>*/}
            {/*    <Wheels/>*/}
            {/*    <Button styles={styles.catalogButton} variant="secondary">*/}
            {/*        В каталог*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );
};

export default SubmittedApplications;
