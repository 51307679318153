import { CircularProgress } from '@material-ui/core';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../app/reducers';
import { Participation } from '../components';
import {useHistory} from "react-router-dom";

export const ParticipationHOC: FC = () => {

  const { isFetching, events } = useSelector((state: AppState) => state.eventStore);

  const history = useHistory();
  const pathPayCashless = history.location.pathname.split('/').find(e => e === 'b2b');

  if (isFetching && events && !(events.length > 0)) {
    return <CircularProgress />;
  } else if(!pathPayCashless) {
    return <Participation />;
  } else {
    return <></>;
  }
};
