import styles from './SeasonResults.module.scss';
import React, { FC, useLayoutEffect, useState } from 'react';
import { infoApi } from '../../api';
import { useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { ReactComponent as Star } from '../../assets/icons/star_24px.svg';

interface ISeasonResults {
  className?: string;
}

interface IResultPilot {
  pilot: string;
  points: number;
}

interface IResult {
  pilot_class: string;
  pilots: IResultPilot[];
}

interface IEventResults {
  name?: string | null;
  results?: IResult[];
}


const TabContent: FC<IResult> = ({ pilots, pilot_class }) => {
  const user = useSelector((store: AppState) => store.userStore);
  const yourName = `${user.first_name} ${user.last_name}`;

  const orderByScore = pilots.sort((a, b) => b.points - a.points);
  return (
    <div className={styles.tabContent}>
      {orderByScore.map((item, index) => {
        const you = yourName === item.pilot;
        return (
          <div key={'tabContent' + index} className={styles.tabItem}>
            <span className={styles.number}>{index + 1}.</span>
            <span className={styles.name}>{item.pilot}</span>
            {you && <Star />}
            <span className={styles.score}>{item.points}</span>
          </div>
        );
      })}
    </div>
  );
};

export const SeasonResults: FC<ISeasonResults> = ({ className }) => {
  const [active, setActive] = useState<number>(0);
  const [results, setResults] = useState<IEventResults[] | null>(null);
  const currentYear: IEventResults | null = results && results[0] || null;

  const openTab = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (!(event.target instanceof HTMLButtonElement)) return;
    setActive(Number(event.target.dataset['index']));
  };

  useLayoutEffect(() => {
    let unmounted = false;
    infoApi.getResults().then((results) => {
      if (results && !unmounted) setResults(results as IEventResults[]);
    });
    return () => {
      unmounted = true;
    }
  }, []);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.tabsHeader}>
        <h2>Результаты сезона</h2>
        <div className={styles.tabContainer}>
          {currentYear && currentYear.results &&
            currentYear.results.length > 0 ?
            currentYear.results.map((result: IResult, i: number) => (
              <button
                key={'seasonButton' + i}
                className={`${styles.tabLinks} ${i === active ? styles.active : ''}`}
                onClick={openTab}
                data-index={i}>
                {result.pilot_class}
              </button>
            )) : 
            <span className={styles.noData}>Нет данных для отображения.</span>
          }
        </div>
      </div>

      {currentYear && currentYear.results && currentYear.results[active] && (
        <TabContent {...currentYear.results[active]} />
      )}
    </div>
  );
};
