import { REMOVE_ERROR, SET_ERROR } from './error.types';

export const setError = (messages: string[]) => ({
  type: SET_ERROR,
  messages,
});

export const removeError = () => ({
  type: REMOVE_ERROR,
});
