import { IEvent } from './event.reducer';

export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_EVENTS = 'SET_EVENTS';
export const REMOVE_EVENTS = 'REMOVE_EVENTS';

interface ISetIsFetching {
  type: 'SET_IS_FETCHING';
  value: boolean;
}

interface ISetCurrentEvent {
  type: 'SET_CURRENT_EVENT';
  id: number;
}

interface ISetEvents {
  type: 'SET_EVENTS';
  events: IEvent[];
}

interface IRemoveEvents {
  type: 'REMOVE_EVENTS';
}

export type EventActions = ISetCurrentEvent | ISetIsFetching | ISetEvents | IRemoveEvents;
