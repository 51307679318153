import styles from './SuccessPaymentModal.module.scss';
import { FC } from 'react';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '../../elements';

type Props = {
  handleClose: () => void;
  open: boolean;
};

export const SuccessPaymentModal: FC<Props> = ({ open, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      style={{ zIndex: 99999 }}
      aria-labelledby="customized-dialog-title"
      open={open}>
        <div className={styles.textContainer}>
            <Success className={styles.logo} />
            <div className={styles.description}>Заявка на покрышки была успешно подана!</div>
        </div>
      <div className={styles.footer}>
        <Button onClick={handleClose}>Продолжить</Button>
      </div>
    </Dialog>
  );
};
