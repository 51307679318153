import { FC, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import styles from './TiresPage.module.scss';
import { TireComponent } from './TireComponent';
import { TiresCart } from './TiresCart';

import { useWindowWidth } from '../../hooks/useWindowWidth';
import { Button } from '../../elements';
import { ReactComponent as FilterList } from '../../assets/icons/filter_list.svg';
import { AppState } from '../../app/reducers';
import { getTires } from '../../app/reducers/tires/tires.actions';
import { AppRoutes } from '../../helpers';

export const TiresPage: FC = () => {
    const dispatch = useDispatch();
    const displayWidth = useWindowWidth();
    const history = useHistory();

    const isTablet = displayWidth <= 912;

    const { tires, isTiresLoading, isTiresLoaded, tiresCart } = useSelector((store: AppState) => ({
        tires: store.tiresStore.tires,
        isTiresLoading: store.tiresStore.isLoading,
        isTiresLoaded: store.tiresStore.isLoaded,
        tiresCart: store.tiresStore.cart,
    }), shallowEqual);

    const isTireInCart = (tireId: number) => {
        const tire = tiresCart.filter(tire => tire.tire === tireId);

        if (tire.length > 0) {
            return true;
        }
        return false;
    };

    const handleGoToCart = () => {
        history.push(AppRoutes.tiresCart);
    };

    useEffect(() => {
        dispatch(getTires());
    }, [dispatch]);

    return (
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.pageHeader}>
                    <h2 className={styles.title}>Заказ покрышек</h2>
                    <div className={styles.filterButtonBlock}>
                        <Button variant="secondary" styles={styles.button}><FilterList className={styles.buttonIcon} />Фильтр</Button>
                    </div>
                </div>
                {isTiresLoading && !isTiresLoaded ? (
                    <div className={styles.progressbarContainer}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={styles.tiresContainer}>
                        {
                            tires?.map(tire => (
                                <div key={tire.id}>
                                    <TireComponent {...tire} isInCart={isTireInCart(tire.id)} />
                                </div>
                            ))
                        }
                    </div>
                )}
                {
                    isTablet && (
                        <div className={styles.actionsBlock}>
                            <Button onClick={handleGoToCart} styles={styles.actionButton} disabled={tiresCart.length === 0}>Продолжить</Button>
                        </div>
                    )
                }
            </div>
            {
                !isTablet && <TiresCart />

            }
        </div>
    );
};
