import scss from './ModalWindow.module.scss';
import React, {FC, MouseEvent, useEffect, useRef, useState} from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy_filled.svg';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Button } from '../Button/Button';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../helpers';
import {orderApi} from "../../api/order.api";
import {fileSaverBill} from "../../helpers/fileSaver";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: '0 40px 0 40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '40px',
      paddingBottom: '28px',
      borderBottom: '1px solid #E9EBF2',
      [theme.breakpoints.down('xs')]: {
        margin: '0 20px 0 20px',
        padding: '30px 16px 15px',
        paddingTop: '30px',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Success className={scss.logo} />
      <h3 className={scss.textCenter}>{children}</h3>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: '28px 40px 40px 40px',
    backgroundColor: '#FAFAFB',
    borderRadius: '4px',
    padding: '12px 12px 4px!important',
    display: 'flex',
    border: '1px solid #E9EBF2',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      margin: '14px 20px 15px 20px',
      height: '100px',
    },
  },
}))(MuiDialogContent);

type Props = {
  handleClose: () => void;
  open: boolean;
};

export const ModalWindow: FC<Props> = ({ open, handleClose }) => {
  const history = useHistory();
  const handleGoOn = () => {
    history.push(AppRoutes.orders);
  };

  let [idOrderPayCashless, setIdOrderPayCashless] = useState<string>('');

  useEffect(() => {
    const unlisten = history.listen(() => {
      const idOrder = history?.location?.search.split('=').pop();
      setIdOrderPayCashless(idOrder ? idOrder : '');
    });
    return () => {
      unlisten();
    };
  }, []);

  const handleBackButtonClick = () => {
    history.push(AppRoutes.main);
  };

  const copy = useRef<HTMLDivElement>(null);

  const downloadBill = async () => {
    const order = await orderApi.downloadBill(Number(idOrderPayCashless));
    if(order) fileSaverBill(order);
  }

  const handleCopyText = (e: MouseEvent<HTMLButtonElement>) => {
    if (copy && copy.current) {
      navigator.clipboard.writeText(copy.current.innerText);
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Заявка на участие успешно подана
      </DialogTitle>
      <DialogContent>
        <div ref={copy} className={scss.textContainer}>
          <div className={scss.textTitleBlock}>
            <span>Счет на оплату был успешно сформирован.</span>
          </div>
          <span>В течение нескольких секунд должно начаться скачивание PDF файла.<br/> Если скачивание не началось, нажмите <a onClick={downloadBill}>Скачать</a></span>

          <p>После оплаты обязательно пришлите нам на электронную почту <br/><a href="mailto:info@motoring.ru" target="_blank">info@motoring.ru</a> копию платежки.</p>

          <p>Без вашего оповещения мы не сможем подтвердить факт оплаты.</p>
        </div>
      </DialogContent>
      <div className={scss.footer}>
        <Button type="reset" onClick={handleBackButtonClick} variant="secondary">
          Закрыть
        </Button>
        <Button onClick={handleGoOn}>Поданные заявки</Button>
      </div>
    </Dialog>
  );
};
