import styles from './FourDigitForm.module.scss';
import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { Button, TextButton } from '../../../elements';
import { ReactComponent as Phone } from '../../../assets/icons/phone.svg';
import { ReactComponent as Error } from '../../../assets/icons/error.svg';
import { useForm } from 'react-hook-form';
import { authUser, clearUser } from '../../../app/reducers/user/user.actions';
import { useDispatch } from 'react-redux';
import { TimerButton } from '../TimerButton/TimerButton';
import { AuthAPI, UserApi } from '../../../api';
import { TokenApi } from '../../../helpers';
import { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface IFourDigitForm {
  user_id: number;
  phone_number: string;
}

interface IFourDigitFormData {
  user_id: number;
  validation_code: string;
}

const validationSchema = yup.object().shape({
  validation_code: yup
    .string()
    .required('Введите код')
    .min(4, 'Введите 4 символа')
    .matches(/^[0-9]+$/, 'Введите код полностью'),
});

export const FourDigitForm: FC<IFourDigitForm> = ({ user_id, phone_number }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();

  const validate = yupResolver(validationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFourDigitFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: validate,
  });

  const onSubmit = async (data: IFourDigitFormData) => {
    try {
      const tokens = await AuthAPI.codeValidate(user_id, data.validation_code);
      TokenApi.setTokens(tokens);
      const userData = await UserApi.getUser();
      dispatch(authUser(userData));
    } catch (err) {
      console.log(err);
    }
  };

  const sendVerificationCode = () => {
    AuthAPI.codeRequest(phone_number);
  };

  const handleBackButtonClick = () => {
    dispatch(clearUser());
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <TextButton
        variant="witharrow"
        type="button"
        onClick={handleBackButtonClick}
        propstyle={styles.textButton}>
        Назад
      </TextButton>
      <h2 className={styles.title}>Авторизация в систему</h2>
      <div className={styles.phoneBlock}>
        <Phone />
        <strong className={styles.phone}>{phone_number}</strong>
      </div>
      <p className={`${styles.text} ${styles.text2}`}>
        Код подтверждения был отправлен на введённый номер телефона через СМС.
      </p>
      <div className={`${styles.inputBlok} ${styles.fourDigitBlock}`}>
        <NumberFormat className={styles.input} format="####" {...register('validation_code')} />
        <label className={styles.label} htmlFor="fourDigit">
          Код подтверждения
        </label>
        {errors.validation_code && <Error className={styles.errorIcon} />}
        {errors.validation_code && (
          <small style={{ color: 'rgb(210 0 1)' }}>{errors?.validation_code?.message}</small>
        )}
      </div>
      <Button type="submit">Продолжить</Button>
      <TimerButton seconds={120} action={sendVerificationCode} />
    </form>
  );
};
