import { ComponentPropsWithRef, FC } from 'react';
import styles from './Checkbox.module.scss';

interface ICheckbox extends ComponentPropsWithRef<'input'> {
  propStyles?: string;
}

export const Checkbox: FC<ICheckbox> = ({ propStyles, children, ...other }) => {
  return (
    <label
      className={`${styles.checkbox} ${propStyles || null} ${other.checked && styles.checked}`}>
      <input {...other} type="checkbox" className={styles.input} />
      {children}
    </label>
  );
};
