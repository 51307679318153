import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import styles from './TiresCart.module.scss';
import { CartItem } from './CartItem';

import { AppState } from '../../app/reducers';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { AppRoutes, formatDDMM, getStartEndDate } from '../../helpers';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { Button, RadioButton, Checkbox } from '../../elements';
import { getShops } from '../../app/reducers/shops/shops.actions';

import { ReactComponent as Cash } from '../../assets/icons/cash.svg';
import { ReactComponent as Writing } from '../../assets/icons/writing.svg';
import { ReactComponent as Credit } from '../../assets/icons/credit.svg';
import { ReactComponent as Sbp } from '../../assets/icons/sbp.svg';
import { setError } from '../../app/reducers/error/error.actions';
import { tiresApi } from '../../api/tires.api';
import { getTires, clearTiresCart } from '../../app/reducers/tires/tires.actions';
import { SuccessPaymentModal } from './SuccessPaymentModal';
import { ErrorPaymentModal } from './ErrorPaymentModal';

export enum PickupPlaceTypes {
    inEvents = 'EVENTS',
    selfPickup = 'SELF_PICKUP',
};

export enum PaymentTypes {
    viaSbp = 'online',
    onSpot = 'onSpot',
    cashless = 'cashless',
    viaCard = 'viaCard',
}

interface SelectOptions {
    value: Record<string, any>,
    label: string,
};

export const TiresCart: FC = () => {
    const dispatch = useDispatch();
    const displayWidth = useWindowWidth();
    const history = useHistory();
    const isTablet = displayWidth <= 912;
    const isMobile = displayWidth <= 520;

    const selectStyles = {
        control: (styles: any, state: any) => ({
            ...styles,
            minHeight: isMobile ? '76px' : '56px',
            background: '#FFFFFF',
            border: '1px solid #E9EBF2',
            boxSizing: 'border-box',
            borderRadius: '4px',
            boxShadow: 'none',
            borderColor: state.isFocused ? '#7D8598 !important' : '#E9EBF2',
            ':active': {
                border: '1px solid #7D8598',
                boxShadow: 'none',
                borderColor: '#7D8598 !important',
            },
            ':hover': {
                border: '1px solid #7D8598',
                boxShadow: 'none',
                borderColor: '#7D8598 !important',
            },
            ':focus': {
                border: '1px solid #7D8598',
                boxShadow: 'none',
                borderColor: '#7D8598 !important',
            },
            ':visited': {
                border: '1px solid #7D8598',
                boxShadow: 'none',
                borderColor: '#7D8598 !important',
            },
        }),
        indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
        placeholder: (styles: any) => ({
            ...styles,
            color: '#7D8598',
            fontSize: '14px',
            lineHeight: '20px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            color: '#334455',
            '>svg': {
                height: '15px',
            }
        }),
        valueContainer: (styles: any) => ({
            ...styles,
            overflow: 'visible',
            height: '100%',
        }),
        option: (styles: any, state: any) => ({
            ...styles,
            minHeight: '56px',
            borderBottom: '1px solid #E9EBF2',
            cursor: 'pointer',
            backgroundColor: state.isFocused ? '#fff !important' : '#fff',
            ':last-child': {
                borderBottom: 'none',
            }
        }),
        menuList: (styles: any) => ({
            ...styles,
        }),
        singleValue: (styles: any) => ({
            ...styles,
            width: '100%',
        }),
    };

    const { tires, tiresCart, user, shops, events } = useSelector((store: AppState) => ({
        tires: store.tiresStore.tires,
        tiresCart: store.tiresStore.cart,
        user: store.userStore,
        shops: store.shopsStore,
        events: store.eventStore,
    }), shallowEqual);

    const [pickupPlace, setPickupPlace] = useState<string>(PickupPlaceTypes.selfPickup);
    const [shopPickupPlaceValue, setShopPickupPlaceValue] = useState<any>(null);
    const [eventPickupPlaceValue, setEventPickupPlaceValue] = useState<any>(null);
    const [shopsOptions, setShopsOptions] = useState<Array<SelectOptions>>([]);
    const [eventsOptions, setEventsOptions] = useState<Array<SelectOptions>>([]);
    const [paymentMethod, setPaymentMethod] = useState<string>(PaymentTypes.viaSbp);
    const [policy, setPolicy] = useState<boolean>(false);
    const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
    const [selectEventValue, setSelectEventValue] = useState<any>(null);
    const [selectShopValue, setSelectShopValue] = useState<any>(null);

    const cart = () => {
        if (tiresCart.length === 0) {
            return <div className={styles.emptyCart}>Корзина пуста</div>;
        }

        const tireIds = tiresCart.map(tire => tire.tire);
        const cartTires = tires.filter(tire => tireIds.includes(tire.id));

        return cartTires?.map(tire => {
            const currentTireInCart = tiresCart.filter(tireItem => tireItem.tire === tire.id)[0];
            return <CartItem {...tire} {...currentTireInCart} key={tire.id} />
        });
    };

    const countTotal = () => {
        const tireIds = tiresCart.map(tire => tire.tire);
        const cartTires = tires.filter(tire => tireIds.includes(tire.id));

        const totalsArray = cartTires.map(tire => {
            let totals = 0;
            tiresCart.forEach(tireItem => {
                if (tire.id === tireItem.tire) {
                    totals = +tire.price * tireItem.number;
                };
            });

            return totals;
        });

        const total = totalsArray.reduce((a, b) => a + b, 0)
        return total;
    };

    const handleGoBack = () => {
        history.push(AppRoutes.tires);
    };

    const formatShopsOptionLabel = ({ value, label }: SelectOptions) => {
        return (
            <div className={styles.selectOption}>
                <div className={styles.selectOptionTitle}>{value.name}</div>
                <div className={styles.selectOptionDescription}>{value.location}</div>
            </div>
        );
    };

    const formatEventsOptionLabel = ({ value, label }: SelectOptions) => {
        const startDate = formatDDMM(value.dates.start_date);
        const endDate = formatDDMM(value.dates.end_date);

        return (
            <div className={styles.selectEventsOption}>
                <div className={styles.selectEventsOptionContainer}>
                    <div className={styles.selectOptionTitle}>{label}</div>
                    <div className={styles.selectOptionDescription}>{value.location}</div>
                </div>
                <div className={styles.selectEventsOptionDate}>{startDate} - {endDate}</div>
            </div>
        );
    };

    const setPickupPlaceToEvent = (place: string) => {
        setShopPickupPlaceValue(null);
        setSelectShopValue(null);
        setPickupPlace(place);
    };

    const setPickupPlaceToShop = (place: string) => {
        setEventPickupPlaceValue(null);
        setSelectEventValue(null);
        setPickupPlace(place);
    };

    const handleChangeEvent = (params: any) => {
        setEventPickupPlaceValue(params);
        setShopPickupPlaceValue(null);
        console.log(params);
        setSelectEventValue(params);
        setSelectShopValue(null);
        setPickupPlace(PickupPlaceTypes.inEvents)
    };

    const handleChangeShop = (params: any) => {
        setShopPickupPlaceValue(params);
        setEventPickupPlaceValue(null);
        console.log(params);
        setSelectShopValue(params);
        setSelectEventValue(null);
        setPickupPlace(PickupPlaceTypes.selfPickup)
    };

    const handlePolicyCheck = () => {
        setPolicy(!policy);
    };

    const handleOpenSuccessModal = () => {
        setOpenSuccessModal(true);
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccessModal(false);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleProcessPayment = async () => {
        let sendData = {};

        if (tiresCart.length === 0) {
            dispatch(setError(['Корзина пуста']));
            return;
        }
        if (tiresCart.length > 0) {
            sendData = {
                ...sendData,
                orders: [
                    ...tiresCart,
                ],
            };
        }

        if (pickupPlace === PickupPlaceTypes.inEvents && !eventPickupPlaceValue) {
            dispatch(setError(['Выберите мероприятие']));
            return;
        }
        if (pickupPlace === PickupPlaceTypes.inEvents && eventPickupPlaceValue) {
            sendData = {
                ...sendData,
                location: eventPickupPlaceValue?.value?.location,
            };
        };

        if (pickupPlace === PickupPlaceTypes.selfPickup && !shopPickupPlaceValue) {
            dispatch(setError(['Выберите магазин']));
            return;
        }
        if (pickupPlace === PickupPlaceTypes.selfPickup && shopPickupPlaceValue) {
            sendData = {
                ...sendData,
                location: shopPickupPlaceValue?.value?.location,
            };
        };

        try {
            const windowRef = window.open();

            if (paymentMethod === PaymentTypes.onSpot && windowRef) {
                windowRef.close();
            };

            setFormSubmitting(true);
            const res = await tiresApi.getOrder(sendData);

            if (res.tire_invoice && res.tire_invoice.invoice_url && windowRef) {
                if (paymentMethod === PaymentTypes.viaSbp) {
                    windowRef.location = res.tire_invoice.invoice_url;
                }
                if (paymentMethod === PaymentTypes.cashless) {
                    windowRef.location.href = `http://localhost:3000${AppRoutes.cashless}?idOrder=${res.tire_invoice.invoice_id}`;
                }
                handleOpenSuccessModal();
                dispatch(getTires());
                dispatch(clearTiresCart());
            }
            setFormSubmitting(false);
        } catch (err) {
            setFormSubmitting(false);
            handleOpenErrorModal();
        };
    }

    useEffect(() => {
        dispatch(getShops());
    }, [dispatch]);

    useEffect(() => {
        if (shops.isLoaded && !shops.isLoading) {
            const shopsStaged = shops.shops.map(shop => ({
                value: {
                    ...shop,
                },
                label: shop.name,
            }));

            setShopsOptions(shopsStaged);
        }
    }, [shops.isLoaded, shops.isLoading, shops.shops]);

    useEffect(() => {
        if (events.events && !events.isFetching) {
            let eventsStaged: Array<SelectOptions> = [];

            for (let i = 0; i <= events.events.length - 1; i++) {
                const option = {
                    label: events.events[i].name,
                    value: {
                        ...events.events[i],
                        location: events.events[i].location,
                        dates: getStartEndDate(events.events[i].event_days),

                    },
                };
                eventsStaged.push(option);
            }

            setEventsOptions(eventsStaged);
        }
    }, [events.events, events.isFetching]);

    return (
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.pageHeader}>
                    {
                        isTablet && (
                            <div className={styles.backButtonContainer}>
                                <div className={styles.backButtonLink} onClick={handleGoBack}>
                                    <ArrowLeft />{' '}Назад
                                </div>
                            </div>
                        )
                    }
                    <h2 className={styles.title}>Корзина</h2>
                    <div className={styles.userInfoBlock}>
                        <div className={styles.name}>{user?.middle_name + ' ' + user?.first_name + ' ' + user?.last_name}</div>
                        <div className={styles.email}>{user?.email}</div>
                    </div>
                </div>
                <div className={styles.cartBlock}>
                    {cart()}
                    <div className={styles.totalPriceContainer}>
                        <div className={styles.priceBlock}>
                            <div className={styles.label}>Итоговая стоимость:</div>
                            <div className={styles.price}>{new Intl.NumberFormat('ru-RU').format(Number(countTotal()))}{' '}<span style={{ fontFamily: 'system-ui' }}>&#8381;</span></div>
                        </div>
                    </div>
                </div>
                <div className={styles.radioBlock}>
                    <div className={styles.subTitle}>
                        Место забора покрышек
                    </div>
                    <div className={styles.radioGroup}>
                        <RadioButton
                            name="events_options"
                            value={PickupPlaceTypes.inEvents}
                            onChange={e => setPickupPlaceToEvent(e.target.value)}
                            checked={pickupPlace === PickupPlaceTypes.inEvents}
                            propStyles={styles.radio}
                            component={
                                <div className={styles.selectComponentWrapper}>
                                    <Select
                                        className={styles.selectComponent}
                                        classNamePrefix="select"
                                        isDisabled={events.isFetching}
                                        isLoading={events.isFetching}
                                        isClearable={false}
                                        isSearchable={false}
                                        name="events"
                                        options={eventsOptions}
                                        styles={selectStyles}
                                        placeholder="Выберите мероприятие"
                                        formatOptionLabel={formatEventsOptionLabel}
                                        onChange={handleChangeEvent}
                                        value={selectEventValue}
                                    />
                                </div>
                            }>
                            <div className={styles.radioText}>На мероприятии</div>
                        </RadioButton>
                        <RadioButton
                            name="events_options"
                            value={PickupPlaceTypes.selfPickup}
                            onChange={e => setPickupPlaceToShop(e.target.value)}
                            checked={pickupPlace === PickupPlaceTypes.selfPickup}
                            propStyles={styles.radio}
                            component={
                                <div className={styles.selectComponentWrapper}>
                                    <Select
                                        className={styles.selectComponent}
                                        classNamePrefix="select"
                                        isDisabled={!shops.isLoaded}
                                        isLoading={shops.isLoading}
                                        isClearable={false}
                                        isSearchable={false}
                                        name="shops"
                                        options={shopsOptions}
                                        styles={selectStyles}
                                        placeholder="Выберите магазин"
                                        formatOptionLabel={formatShopsOptionLabel}
                                        onChange={handleChangeShop}
                                        value={selectShopValue}
                                    />
                                </div>
                            }>
                            <div className={styles.radioText}>Самовывоз из магазина</div>
                        </RadioButton>
                    </div>
                </div>
                <div className={styles.paymentsBlock}>
                    <div className={styles.subTitle}>
                        Выбор способа оплаты
                    </div>
                    <div className={styles.radioGroup}>
                        <RadioButton
                            name="payment_methods"
                            value={PaymentTypes.viaSbp}
                            onChange={e => setPaymentMethod(e.target.value)}
                            checked={paymentMethod === PaymentTypes.viaSbp}
                            propStyles={styles.paymentRadio}>
                            <Sbp />
                            <span>Оплата по СБП</span>
                        </RadioButton>
                        {/* <RadioButton
                            name="payment_methods"
                            value={PaymentTypes.viaCard}
                            onChange={e => setPaymentMethod(e.target.value)}
                            checked={paymentMethod === PaymentTypes.viaCard}
                            propStyles={styles.paymentRadio}>
                            <Credit />
                            <span>Картой онлайн</span>
                        </RadioButton>
                        <RadioButton
                            name="payment_methods"
                            value={PaymentTypes.onSpot}
                            onChange={e => setPaymentMethod(e.target.value)}
                            checked={paymentMethod === PaymentTypes.onSpot}
                            propStyles={styles.paymentRadio}>
                            <Cash />
                            <span>Картой или наличными при получении</span>
                        </RadioButton> */}
                        <RadioButton
                            name="payment_methods"
                            value={PaymentTypes.cashless}
                            onChange={e => setPaymentMethod(e.target.value)}
                            checked={paymentMethod === PaymentTypes.cashless}
                            propStyles={styles.paymentRadio}>
                            <Writing style={{ marginLeft: '3px' }} />
                            <span>Безналом для юр. лиц</span>
                        </RadioButton>
                    </div>
                </div>
            </div>
            <div className={styles.actions}>
                <Checkbox checked={policy} onChange={handlePolicyCheck} propStyles={styles.policy}>
                    <span>Согласен с политикой конфиденциальности и возврата при оплате картой. </span>
                </Checkbox>
                <div className={styles.subpolicy}>
                    Нажимая кнопку “Оплатить”, Вы соглашаетесь с{' '}
                    <a href="https://motorrika.ru/contract-of-public-offer" target="_blank" rel="noopener noreferrer">
                        офертой
                    </a>
                    .{' '}
                </div>
                {
                    isTablet && (
                        <div className={styles.actionsBlock}>
                            <Button styles={styles.backButton} onClick={handleGoBack}>Назад</Button>
                        </div>
                    )
                }
                <Button onClick={handleProcessPayment} styles={styles.payButton} disabled={!policy || isFormSubmitting}>
                    Оплатить
                </Button>
            </div>
            <SuccessPaymentModal open={openSuccessModal} handleClose={handleCloseSuccessModal} />
            <ErrorPaymentModal open={openErrorModal} handleClose={handleCloseErrorModal} />
        </div>
    );
};
