import styles from './EditUser.module.scss';
import { ChangeEvent, FC, useState } from 'react';
import { TextInput } from '../../hoc/TextInput';
import { SelectAsync } from '../SelectAsync/SelectAsync';
import { TeamSelect } from '../Selects/TeamSelect';
import { MotoBrand } from '../Selects/MotoBrand';
import { MotoModel } from '../Selects/MotoModel';
import { IUser } from '../../app/reducers';
import { useFormContext } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/ru';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

moment.locale('ru');

type EditUserProps = {
    user?: IUser;
};

export const EditUser: FC<EditUserProps> = ({ user }) => {
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext();
    const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
        moment(user?.date_of_birth),
    );
    return (
        <div className={styles.container}>
            <div className={styles.half}>
                <TextInput
                    label="Фамилия"
                    {...register('last_name')}
                    error={errors.last_name}
                    helperText={errors?.last_name?.message}
                    defaultValue={user?.last_name || undefined}
                />
                <TextInput
                    label="Имя"
                    {...register('first_name')}
                    error={errors.first_name}
                    helperText={errors?.first_name?.message}
                    defaultValue={user?.first_name}
                />
                <TextInput
                    label="Отчество"
                    {...register('middle_name')}
                    error={errors.middle_name}
                    helperText={errors?.middle_name?.message}
                    defaultValue={user?.middle_name}
                />
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'ru'}>
                    <KeyboardDatePicker
                        clearable
                        value={selectedDate}
                        placeholder="10/10/2012"
                        onChange={(date) => {
                            handleDateChange(date);
                            setValue('date_of_birth', date, { shouldDirty: true });
                        }}
                        InputLabelProps={{ shrink: true }}
                        format="DD/MM/yyyy"
                        inputVariant="filled"
                        label="Дата рождения"
                        InputProps={{
                            disableUnderline: true,
                        }}
                        error={errors.date_of_birth}
                        helperText={errors?.date_of_birth?.message}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <TextInput
                type="email"
                label="E-mail"
                {...register('email')}
                error={errors.email}
                helperText={errors?.email?.message}
                defaultValue={user?.email}
            />
            <SelectAsync defaultValue={user?.location} />
            <TeamSelect defaultValue={user?.pilot?.pilot_team} />
            <MotoBrand defaultValue={user?.pilot?.motorcycle_brand} />
            <MotoModel defaultValue={user?.pilot?.motorcycle_model} />
            {!user?.pilot?.number && (
                <TextInput
                    type="number"
                    label="Желаемый стартовый номер"
                    {...register('number')}
                    onInput={(e: ChangeEvent<HTMLInputElement>) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                    }}
                    error={errors.number}
                    helperText={errors?.number?.message}
                />
            )}
        </div>
    );
};
