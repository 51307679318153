import styles from './PayCashless.module.scss';
import { ChangeEvent } from 'react';
import { UserApi} from '../../api';
import { orderApi } from '../../api/order.api';
import { useDispatch, useSelector } from 'react-redux';
import {AppRoutes} from "../../helpers";
import {useHistory} from "react-router-dom";
import { Button, TextButton} from "../../elements";
import {TextInput} from "../../hoc/TextInput";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {payCashless} from "../../helpers/validation";
import {authUser} from "../../app/reducers/user/user.actions";
import { fileSaverBill } from "../../helpers/fileSaver";
import { AppState } from '../../app/reducers';

const PayCashless = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleBackButtonClick = () => {
    history.push(AppRoutes.main);
  };

  const methods = useForm({ resolver: yupResolver(payCashless), reValidateMode: 'onBlur' });
  const {
    register,
    formState: { errors },
  } = methods;

  const {
    kpp,
    inn,
    company_name,
    company_address,
  } = useSelector((state: AppState) => state.userStore);

  const onSubmit = async (data: any) => {
    const idOrder = history.location.search.split('=').pop();
    const res = await UserApi.patchUser(data);
    if (res) {
      dispatch(authUser(res));
      history.push(AppRoutes.main + `?idOrder=${idOrder}` + '#success');
    }
    const order = await orderApi.downloadBill(Number(idOrder));
    if(order) fileSaverBill(order);
  };

  return (
      <div className={styles.cashlessContainer}>
        <div className={styles.cashless}>

          <FormProvider {...methods}>
            <form className={styles.formCashless} onSubmit={methods.handleSubmit(onSubmit)}>
              <div className={styles.formWrapper}>
                <div style={{paddingTop: '20px'}}>
                  <TextButton onClick={handleBackButtonClick} variant="witharrow">
                    Назад
                  </TextButton>
                  <h2 className={styles.formTitle}>Оплата безналом для юр. лиц</h2>
                </div>
                <div>
                  <h3 className={styles.formSubtitle}>Данные юр. лица</h3>
                  <TextInput
                    label="Название компании"
                    {...register("company_name")}
                    error={!!errors.company_name}
                    helperText={errors?.company_name?.message}
                    defaultValue={company_name ? company_name : ''}
                  />
                  <div className={styles.formDetail}>
                <span>
                  С указанием формы собственности ООО/ИП/ЧУП и др.
                </span>
                  </div>
                </div>

                <TextInput
                  label="ИНН"
                  {...register("inn")}
                  error={!!errors.inn}
                  helperText={errors?.inn?.message}
                  defaultValue={inn ? inn : ''}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/\D+/g, '');
                  }}
                />

                <TextInput
                  label="КПП"
                  {...register("kpp")}
                  error={!!errors.kpp}
                  helperText={errors?.kpp?.message}
                  defaultValue={kpp ? kpp : ''}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/\D+/g, '');
                  }}
                />

                <div>
                  <TextInput
                    label="Юридический адрес"
                    {...register("company_address")}
                    error={!!errors.company_address}
                    helperText={errors?.company_address?.message}
                    defaultValue={company_address ? company_address : ''}
                  />

                  <div className={styles.formDetail}>
                <span>
                  Индекс, страна, область, город, улица, дом(корпус), офис.
                </span>
                  </div>
                </div>
              </div>

              <div className={styles.buttons}>
                <Button type="reset" styles={styles.button} onClick={handleBackButtonClick} variant="secondary">
                  Отмена
                </Button>
                <Button type="submit" styles={styles.button}>
                  Продолжить
                </Button>
              </div>
            </form>
          </FormProvider>

        </div>
      </div>
  );
};

export default PayCashless;
