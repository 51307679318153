import { Autocomplete } from '@material-ui/lab';
import { useEffect } from 'react';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { infoApi } from '../../api';
import { TextInput } from '../../hoc/TextInput';
import { IMotoBrands, IMotoModels } from './MotoBrand';

type MotoModelType = {
  name: string;
  id?: number;
};

type MotoModelProps = {
  defaultValue?: string | null;
}

export const MotoModel: FC<MotoModelProps> = ({defaultValue}) => {
  const [options, setOptions] = useState<MotoModelType[]>([]);
  const { setValue, control, watch, formState: {errors} } = useFormContext();
  const motoBrandField = watch('motorcycle_brand')
  
  useEffect(() => {
    let unmounted = false;
    const fetchMoto = async () => {
      try {
        const moto: IMotoBrands[] = await infoApi.getMotoBrands();
        if (!unmounted) {
          const models: IMotoModels[] = moto.find(item => item.id === motoBrandField.id)?.models || [];
          if (models.length > 0) {
            setOptions(models);
          }
        }
      } catch (err) {
        if (!unmounted) console.log(err);
      }
    };
    fetchMoto();
    return () => {
      unmounted = true;
    };
  }, [motoBrandField]);

  return (
    <Controller
      name="motorcycle_model"
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value } }) => (
        <Autocomplete
          value={value}
          freeSolo
          autoSelect
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') setValue('motorcycle_model', newValue);
            else if (newValue && newValue.name) setValue('motorcycle_model', newValue.name);
            else if (!newValue) setValue('motorcycle_model', '');
          }}
          options={options}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.name) {
              return option.name;
            }
            return option.name;
          }}
          getOptionSelected={(option, val) => option.name === val.name}
          renderInput={(params) => (
            <TextInput
              {...params}
              label="Модель мотоцикла"
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              variant="filled"
              error={errors.motorcycle_model}
              helperText={errors?.motorcycle_model?.message}
            />
          )}
        />
      )}
    />
  );
};
