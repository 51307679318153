import { IEventDays } from '../app/reducers/event/event.reducer';

export const formatPhoneNumber = (phone_number: string): string =>
    phone_number.replace(/[^+\d]+/g, '');

export const formatDate = (date: string): string =>
    new Date(date).toLocaleDateString('ru-RU', {
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        timeZone: 'UTC',
    });

export const formatDDMM = (date: string): string =>
    new Date(date).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long', timeZone: 'UTC' });

export const formatDDMMYYYY = (date: Date): string => {
    const formattedDate = new Intl.DateTimeFormat('fr-CA').format(date);
    return formattedDate
};

export const sortDateAsc = (arr: IEventDays[]): IEventDays[] => {
    return arr.sort((a: IEventDays, b: IEventDays) => {
        const aDate: any = new Date(a.date);
        const bDate: any = new Date(b.date);
        return aDate - bDate;
    });
};

export const getStartEndDate = (arr: IEventDays[]): { start_date: string; end_date: string } => {
    const sortedDate = sortDateAsc(arr);
    const start_date = sortedDate[0].date;
    const end_date = sortedDate[sortedDate.length - 1].date;
    return {
        start_date,
        end_date,
    };
};
