import styles from './TimerButton.module.scss';
import { FC, useEffect, useState } from 'react';
import { ITextButton, TextButton } from '../../../elements';

interface ITimerButton extends ITextButton {
  seconds: number;
  action: () => void;
}

export const TimerButton: FC<ITimerButton> = ({ seconds, action }) => {
  const [time, setTime] = useState<number>(seconds);

  function pad(n: number) {
    return n < 10 ? '0' + n.toString() : n.toString();
  }

  useEffect(() => {
    if (time <= 0) return;
    let timer = setTimeout(() => setTime(time - 1), 1000);
    return () => clearTimeout(timer);
  }, [time]);

  const handleClick = () => {
    setTime(seconds);
    action();
  };
  return (
    <div className={styles.timerBlock}>
      <span className={styles.timer}>{`${pad(Math.floor(time / 60))}:${pad(
        time - Math.floor(time / 60) * 60,
      )}`}</span>
      <TextButton onClick={handleClick} disabled={!(time <= 0)}>
        Отправить код повторно
      </TextButton>
    </div>
  );
};
