import api from './axios.main';

export enum userRoutes {
  currentUser = 'users/current/',
}

export interface UserFetchData {
  email: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_of_birth: string;
  location: string;
  motorcycle_brand: number;
  pilot_team: string;
  motorcycle_model: string;
  number: number;
  photo?: FormData
}

export const UserApi = {
  getUser() {
    return api.get(userRoutes.currentUser).then((res) => res.data);
  },
  patchUser(data: any) {
    return api.patch(userRoutes.currentUser, data).then((res) => res.data);
  },
  putUser(user: UserFetchData) {
    return api.put(userRoutes.currentUser, user).then((res) =>  res.data);
  },
};
