import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './CartItem.module.scss';

import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { incrementTireInCart, decrementTireInCart, removeTireFromCart } from '../../app/reducers/tires/tires.actions';
import { Tire, TireInCart } from '../../app/reducers/tires/tires.types';
import { TireDescriptionModal } from './TireDescriptionModal';

interface TireProps extends Tire, TireInCart {
}

export const CartItem: FC<TireProps> = ({
    id,
    logo,
    name,
    price,
    number,
    ...rest
}) => {
    const dispatch = useDispatch();
    const [openTireDescriptionModal, setOpenTireDescriptionModal] = useState<boolean>(false);

    const handleDecrementTireQuantity = (e: any) => {
        e.preventDefault();
        if (number === 1) {
            return;
        }

        dispatch(decrementTireInCart(id))
    };

    const handleOpenTireDescriptionModal = () => {
        setOpenTireDescriptionModal(true);
    };

    const handleCloseTireDescriptionModal = () => {
        setOpenTireDescriptionModal(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.mediaBlock}>
                <div className={styles.imgBlock}><img src={logo} alt={name} /></div>
                <div className={styles.main}>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.priceBlock}>
                        <div className={styles.counterBlock}>
                            <div className={styles.counter}>
                                <button className={`${styles.minus} ${number === 1 && styles.disabled}`} onClick={handleDecrementTireQuantity} disabled={number === 1}><Minus /></button>
                                <div className={styles.input}><div>{number}</div></div>
                                <button className={styles.plus} onClick={() => dispatch(incrementTireInCart(id))}><Plus /></button>
                            </div>
                        </div>
                        <div className={styles.price}>
                            {new Intl.NumberFormat('ru-RU').format(Number(+price * number))}{' '}<span style={{ fontFamily: 'system-ui' }}>&#8381;</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.actionBlock}>
                <div className={styles.info} onClick={handleOpenTireDescriptionModal}><Info /></div>
                <div className={styles.delete} onClick={() => dispatch(removeTireFromCart(id))}><Delete /></div>
            </div>
            <TireDescriptionModal open={openTireDescriptionModal} handleClose={handleCloseTireDescriptionModal} tire={{
                id,
                logo,
                name,
                price,
                ...rest
            }}  />
        </div>
    );
};
