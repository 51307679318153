import { FC, useEffect } from 'react';

import { ReactComponent as Logo } from '../../assets/motoringLogo.svg';

import styles from './Auth.module.scss';

import { useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { FourDigitForm } from './FourDigitForm/FourDigitForm';
import { PhoneNumberForm } from './PhoneNumberForm/PhoneNumberForm';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../helpers';

export const Authorization: FC<any> = () => {
  const { isAuthorized, phone_number, id } = useSelector((state: AppState) => state.userStore);
  const history = useHistory();

  useEffect(() => {
    if (isAuthorized) history.push(AppRoutes.main);
  }, [isAuthorized, history]);
  return (
    <div className={styles.auth}>
      <Logo className={styles.logo} />
      {id && phone_number ? (
        <FourDigitForm phone_number={phone_number} user_id={id} />
      ) : (
        <PhoneNumberForm />
      )}
    </div>
  );
};
