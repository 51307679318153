import styles from './EditUserPage.module.scss';
import { ChangeEvent, FC, useLayoutEffect, useState } from 'react';
import { Avatar, Button, EditUser, IMotoBrands, TextButton } from '../../elements';
import { TextInput } from '../../hoc/TextInput';
import { useHistory } from 'react-router-dom';
import { AppRoutes, formatDDMMYYYY } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { infoApi, UserApi } from '../../api';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { FormProvider, useForm } from 'react-hook-form';
import api from '../../api/axios.main';
import { AxiosResponse } from 'axios';
import { authUser } from '../../app/reducers/user/user.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { editUserPageSchema } from '../../helpers/validation';
import { setError } from '../../app/reducers/error/error.actions';
import Compressor from 'compressorjs';
import { useEffect } from 'react';
import { Moment } from 'moment';

export const EditUserPage: FC = () => {
    const [defaultMotoBrand, setDefaultMotoBrand] = useState<IMotoBrands | null>(null);

    const dispatch = useDispatch();

    const user = useSelector((state: AppState) => state.userStore);
    const {
        isAuthorized,
        isLoading,
        is_profile_exist,
        photo,
        bio,
        achievements,
        partners,
        ...restUser
    } = user;

    useLayoutEffect(() => {
        let unmounted = false;
        infoApi
            .getMotoBrands()
            .then((data: IMotoBrands[]) => {
                if (data && !unmounted) {
                    const defaultMoto =
                        data.find((moto) => moto.name === user.pilot?.motorcycle_brand) || null;
                    setDefaultMotoBrand(defaultMoto);
                }
            })
            .catch((err) => console.log(err));
        return () => {
            unmounted = true;
        };
    }, []);

    const history = useHistory();

    const methods = useForm({ resolver: yupResolver(editUserPageSchema) });
    const {
        formState: { errors },
    } = methods;

    useEffect(() => {
        const firstErr = Object.values(errors)[0];
        if (firstErr && firstErr.ref && firstErr.ref.scrollIntoView)
            firstErr.ref.scrollIntoView({ block: 'center' });
    }, [errors]);

    const handleBackButtonClick = () => {
        history.push(AppRoutes.main);
    };

    const handleDeletePhoto = async () => {
        const formData = new FormData();
        formData.append('photo', '');
        const res = await api.patch('users/current/', formData, {
            headers: {
                accept: 'application/json',
                'Content-Type':
                    'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW;',
            },
        });
        if (res) {
            history.go(0);
        }
    };

    const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        const MAX_IMG_SIZE = 6291456;
        const form = new FormData();
        const img = e.currentTarget.files && e.currentTarget.files[0];
        if (img && img.size <= MAX_IMG_SIZE) {
            const MAX_SERVER_SIZE = 1048576;
            new Compressor(img, {
                convertSize: MAX_SERVER_SIZE,
                quality: 0.6,
                width: 1024,
                height: 1024,
                success(result) {
                    form.append('photo', result, img.name);
                    api.patch('users/current/', form, {
                        headers: {
                            accept: 'application/json',
                            'Content-Type':
                                'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW;',
                        },
                    }).then((res: AxiosResponse) => {
                        if (res && res.status >= 200 && res.status < 300) {
                            history.go(0);
                        }
                    });
                },
            });
        }
        if (img && img.size > MAX_IMG_SIZE) {
            dispatch(setError(['Размер изоображения превышает допустимый']));
        }
    };

    const onSubmit = async (data: any) => {
        const motorcycle_brand = data.motorcycle_brand
            ? data.motorcycle_brand.id
            : defaultMotoBrand?.id;
        const filteredUndefined = JSON.parse(JSON.stringify({ ...data, motorcycle_brand }));
        const formData = {
            ...filteredUndefined,
        };
        if (data.date_of_birth) {
            const date = data.date_of_birth as Date;
            formData.date_of_birth = formatDDMMYYYY(date);
        }
        const res = await UserApi.patchUser(formData);
        if (res) {
            dispatch(authUser(res));
            history.push(AppRoutes.main);
        }
    };

    return (
        <FormProvider {...methods}>
            <form className={styles.wrapper} onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <TextButton variant="witharrow" onClick={handleBackButtonClick}>
                            {' '}
                            Назад
                        </TextButton>
                        <h2 className={styles.title}>Редактировать профиль</h2>
                    </div>
                    <div className={styles.main}>
                        <div className={styles.avatarBlock}>
                            <Avatar src={photo} propstyle={styles.avatar} />
                            <span>
                                Допустимый формат: jpeg; png
                                <br />
                                Максимальный размер: 6 mb
                                <br />
                                Рекомендуемое разрешение: 1024х1024 px
                            </span>
                            <div className={styles.avatarMobileButtons}>
                                <Button
                                    styles={styles.avatarMobileButton}
                                    onClick={handleDeletePhoto}
                                    variant="secondary">
                                    <Delete /> Удалить
                                </Button>
                                <label className={styles.avatarMobileButton}>
                                    <input
                                        hidden
                                        type="file"
                                        accept="image/jpeg,image/png"
                                        onChange={onUploadFile}
                                    />
                                    <Edit className={styles.buttonIcon} />
                                    Изменить
                                </label>
                            </div>
                        </div>

                        <div className={styles.formBlock}>
                            <h3 className={styles.formSubtitle}>Основная информация</h3>
                            <EditUser user={restUser} />
                            <h3 className={styles.formSubtitle}>Автобиография</h3>
                            <TextInput
                                label="О себе"
                                type="text"
                                multiline
                                {...methods.register('bio')}
                                defaultValue={bio}
                            />
                            <h3 className={styles.formSubtitle}>Спортивные достижения</h3>
                            <TextInput
                                label="Ваши спортивные достижения"
                                type="text"
                                multiline
                                defaultValue={achievements}
                                {...methods.register('achievements')}
                            />
                            <h3 className={styles.formSubtitle}>Партнеры</h3>
                            <TextInput
                                label="Ваши партнеры"
                                type="text"
                                multiline
                                {...methods.register('partners')}
                                defaultValue={partners}
                            />
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Button type="reset" onClick={handleBackButtonClick} variant="secondary">
                            Отмена
                        </Button>
                        <Button type="submit">Сохранить</Button>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
