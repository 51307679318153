import { EventActions, REMOVE_EVENTS, SET_CURRENT_EVENT, SET_EVENTS, SET_IS_FETCHING } from "./event.types";

export interface IAdditionalService {
  id: number,
  name: string,
}

export interface IEventDays {
  id: number,
  common_price: string,
  special_price: string,
  date: string,
  photo_training_price: string,
  photo_race_price: string,
}

export interface IPilotClass {
  id: number,
  name: string,
  type: string,
}

export interface IPilotClassesObj {
  [key: string]: IPilotClass[]
}

export interface IEvent {
  id: number;
  name: string;
  pilot_classes: IPilotClassesObj;
  additional_services: IAdditionalService[],
  logo: string;
  location: string;
  event_days: IEventDays[],
  info: string;
  has_order: boolean;
}

export interface EventState {
  events: IEvent[],
  currentEvent: number,
  isFetching: boolean,
}

const initialState: EventState = {
  events: [],
  currentEvent: 0,
  isFetching: true,
};

export const EventReducer = (state: EventState = initialState, action: EventActions) => {
  switch (action.type) {
    case SET_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: action.id
      }
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.value
      }
    case SET_EVENTS:
      return {
        ...state,
        events: action.events
      }
    case REMOVE_EVENTS:
      return {
        ...state,
        events: [],
      }
    default:
     return {
       ...state,
     }
  }
}