import { CircularProgress } from '@material-ui/core';
import { FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { eventsFetch } from '../../app/reducers/event/event.actions';
import { EventButton } from '../../elements';
import { getStartEndDate } from '../../helpers';
import styles from './UpcomingEvents.module.scss';

interface IUpcomingEvents {
  className?: string;
}

export const UpcomingEvents: FC<IUpcomingEvents> = (props) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(eventsFetch());
  }, [dispatch]);

  const { isFetching, events } = useSelector((state: AppState) => state.eventStore);

  return (
    <div className={`${styles.container} ${props.className}`}>
      <h2>Предстоящие мероприятия</h2>
      <div className={styles.buttonsContainer}>
        {isFetching ? (
          <CircularProgress />
        ) : (
          events &&
          events.map((event) => {
            const dates = getStartEndDate(event.event_days);
            return (
              <EventButton
                name={event.name}
                location={event.location}
                logo={event.logo}
                end_date={dates.end_date}
                start_date={dates.start_date}
                key={`event${event.id}`}
                id={event.id}
                has_order={event.has_order}
              />
            );
          })
        )}
      </div>
    </div>
  );
};
