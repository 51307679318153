export enum AppRoutes {
  auth = '/auth',
  main = '/main',
  event = '/main/event',
  editUser = '/edit',
  orders = '/main/orders',
  tires = '/main/tires',
  tiresCart = '/main/cart',
  cashless = '/main/event/b2b'
}
