import api from './axios.main';

export enum authRoutes {
  codeRequest = 'auth/code/request/',
  codeValidate = 'auth/code/validate/',
  tokenRefresh = 'auth/token/refresh/',
  tokenVerify = 'auth/token/verify/',
}

export const AuthAPI = {
    codeRequest(phone_number: string) {
        return api.post(authRoutes.codeRequest, { phone_number }).then(res => res.data);
    },

    codeValidate(user_id: number, validation_code: string) {
      return api.post(authRoutes.codeValidate, { user_id, validation_code }).then(res => res.data);
    },

    refreshToken(refresh: string) {
        return api.post(authRoutes.tokenRefresh, { refresh }).then(res => res.data);
    },

    tokenVerify(token: string) {
        return api.post(authRoutes.tokenVerify, { token }).then(res => res.data);
    }
}