import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './NavbarMobile.module.scss';
import { ReactComponent as Logo } from '../../assets/motoringLogo.svg';
import { ReactComponent as Cart } from '../../assets/icons/cart.svg';
import { AppRoutes } from '../../helpers';
import { Link } from 'react-router-dom';
import { AppState } from '../../app/reducers';

interface IMobileHeader {
  action: () => void;
  expanded: boolean,
};

export const MobileHeader = forwardRef<any, IMobileHeader>((props, ref) => {
  const tiresCart = useSelector((store: AppState) => store.tiresStore.cart);
  return (
    <div ref={ref} className={styles.container}>
      <Link to={AppRoutes.main}>
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.actions}>
        {
          tiresCart.length > 0 && (
            <Link to={AppRoutes.tiresCart}>
              <div className={styles.cartBlock}>
                <div className={styles.cartIcon}><Cart /></div>
                <div className={styles.cartItemsCount}>{tiresCart.length}</div>
              </div>
            </Link>
          )
        }
        <button type="button" className={`${styles.hamburgerContainer} ${props.expanded && styles.opened}`} onClick={props.action}>
          <svg width="30" height="30" viewBox="0 0 100 100">
            <path className={`${styles.line} ${styles.line1}`} d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
            <path className={`${styles.line} ${styles.line2}`} d="M 20,50 H 80" />
            <path className={`${styles.line} ${styles.line3}`} d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
          </svg>
        </button>
      </div>
    </div>
  );
});
