import styles from './Calculator.module.scss';
import { FC } from 'react';
import { ReactComponent as Cash } from '../../assets/icons/cash.svg';
import { ReactComponent as Writing } from '../../assets/icons/writing.svg';
import { ReactComponent as Credit } from '../../assets/icons/credit.svg';
import { ReactComponent as Sbp } from '../../assets/icons/sbp.svg';
import { Button, Checkbox, RadioButton } from '../../elements';
import { Field, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { useState } from 'react';
import { IEvent } from '../../app/reducers/event/event.reducer';
import { IFromEvent } from './Participation';
import { formatDDMM } from '../../helpers';

interface IPositionElement {
  index: number;
  title: string;
  content: string;
  price?: number | 'Фото';
}

export enum PaymentTypes {
  online = 'online',
  onSpot = 'onSpot',
  cashless = 'cashless',
}

const notFilled = 'Не заполнено';

export const PositionElement: FC<IPositionElement> = (props) => {
  const { index, title, content, price } = props;
  return (
    <div className={styles.position}>
      <span className={styles.positionIndex}>{index}</span>
      <div className={styles.positionContentBlock}>
        <span className={styles.positionTitle}>{title}</span>
        <p className={styles.positionContent}>{content}</p>
      </div>
      {typeof price === 'number' ? (
        <strong className={styles.positionPrice}>{price} ₽</strong>
      ) : (
        <span className={styles.inPlace}>
          Оплата на <br />
          месте
        </span>
      )}
    </div>
  );
};

interface ICalculator {
  event: IEvent;
}

type ResultItem = {
  title: string;
  subtitle: string;
  cost: number | 'Фото';
  day: number;
};

type Photo = {
  event_day: number;
  has_training_photo: boolean;
  has_race_photo: boolean;
};

export const Calculator: FC<ICalculator> = ({ event }) => {
  const form = useFormState();
  const { last_name, first_name, middle_name, email } = useSelector(
    (state: AppState) => state.userStore,
  );

  const [policy, setPolicy] = useState<boolean>(false);

  const handlePolicyCheck = () => {
    setPolicy(!policy);
  };
  const formValues = form.values;

  const daysArr: ResultItem[] =
    formValues.events &&
    formValues.events.map((val: IFromEvent) => {
      if (val.event_day && event.event_days && event.event_days.length > 0) {
        const currentEvent = event.event_days.find((a) => a.id === val.event_day);
        const commonPrice =
          formValues.pilot_common_class && currentEvent ? parseInt(currentEvent.common_price) : 0;
        const specialPrice =
          formValues.pilot_special_class && currentEvent ? parseInt(currentEvent.special_price) : 0;
        const cost = (currentEvent && commonPrice + specialPrice) || 0;
        const date = currentEvent ? formatDDMM(currentEvent.date) : null;
        if (currentEvent)
          return {
            day: currentEvent.id,
            title: 'Дата',
            subtitle: date,
            cost,
          };
      }
    });

  const additionalServices: ResultItem[] =
    (formValues.additional_services &&
      formValues.additional_services.map((val: number) => {
        const serviceContent = event.additional_services.find((a) => a.id === val)?.name;
        if (serviceContent)
          return {
            day: 9999,
            title: 'Услуги',
            subtitle: serviceContent,
            cost: 'Фото',
          };
      })) ||
    [];

  const photoArr: ResultItem[] =
    formValues.events &&
    formValues.events.map((val: IFromEvent) => {
      if (val.event_day && event.event_days && event.event_days.length > 0) {
        const currentEvent = event.event_days.find((a) => a.id === val.event_day);
        const currentPhotoData: Photo = formValues.photo.find(
          (a: Photo) => a.event_day === val.event_day,
        );
        if (currentPhotoData) {
          const date = currentEvent ? formatDDMM(currentEvent.date) : null;
          const { has_training_photo, has_race_photo } = currentPhotoData;
          if (currentEvent && (has_race_photo || has_training_photo))
            return {
              day: currentEvent.id,
              title: 'Фотосессия',
              subtitle: `${date} (${has_training_photo ? 'тренировка' : ''}${
                has_race_photo && has_training_photo ? ', ' : ''
              }${has_race_photo ? 'гонка' : ''})`,
              cost: 'Фото',
            };
        }
      }
    });

  const resultArr = [...daysArr, ...photoArr, ...additionalServices].sort((a, b) => a.day - b.day);

  const summaryPrice = daysArr.reduce((a: number, item: ResultItem) => {
    return a + (item && item.cost && typeof item.cost === 'number' ? item.cost : 0);
  }, 0);

  return (
    <div className={styles.container}>
      <h2>Калькулятор стоимости</h2>
      <strong className={styles.userName}>
        {last_name} {first_name} {middle_name}
      </strong>
      <span className={styles.userMail}>{email ? email : notFilled}</span>
      <div className={styles.positions}>
        {resultArr &&
          resultArr.map((item: any, index: number) => {
            if (item && item.title && item.subtitle && item.cost) {
              return (
                <PositionElement
                  title={item.title}
                  content={item.subtitle}
                  index={index + 1}
                  price={item.cost}
                />
              );
            }
          })}
      </div>
      <div className={styles.summaryBlock}>
        <span>Итоговая стоимость:</span>
        <span className={styles.summary}>{`${summaryPrice} ₽`}</span>
      </div>
      <h3 className={styles.radioTitle}>Выбор способа оплаты</h3>
      {/*<Field type="radio" value={PaymentTypes.onSpot} name="paymentType">*/}
      {/*  {(props) => (*/}
      {/*    <RadioButton {...props.input} propStyles={styles.radio}>*/}
      {/*      <Cash />*/}
      {/*      <span>Картой или наличными на мероприятии</span>*/}
      {/*    </RadioButton>*/}
      {/*  )}*/}
      {/*</Field>*/}
      <Field type="radio" value={PaymentTypes.online} name="paymentType">
        {(props) => (
          <RadioButton {...props.input} propStyles={styles.radio}>
            <Sbp />
            <span>Оплата по СБП</span>
          </RadioButton>
        )}
      </Field>
      <Field type="radio" value={PaymentTypes.cashless} name="paymentType">
        {(props) => (
          <RadioButton {...props.input} propStyles={styles.radio}>
            <Writing />
            <span>Безналом для юр. лиц</span>
          </RadioButton>
        )}
      </Field>
      {/* <RadioButton
        onChange={handlePaymentChange}
        name="paymentType"
        checked={paymentType.online}
        value={PaymentTypes.online}
        propStyles={styles.radio}>

      </RadioButton> */}
      <Checkbox checked={policy} onChange={handlePolicyCheck} propStyles={styles.policy}>
        <span>Согласен с политикой конфиденциальности и возврата при оплате картой. </span>
      </Checkbox>
      <span className={styles.subpolicy}>
        Нажимая кнопку “Оплатить”, Вы соглашаетесь с{' '}
        <a href="https://motorrika.ru/contract-of-public-offer" target="_blank">
          офертой
        </a>
        .{' '}
      </span>
      <Button type="submit" disabled={!policy || form.submitting}>
        Оплатить
      </Button>
    </div>
  );
};
