import { FC } from 'react';
import {
  Avatar as AvatarCustom,
  Button,
  CommentButton,
  ModalEdit,
  Portal,
  TextButton,
} from '../../elements';
import styles from './UserHeader.module.scss';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Flag } from '../../assets/icons/flag.svg';
import { useSelector } from 'react-redux';
import { AppState } from '../../app/reducers';
import { AppRoutes, formatDate } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { useState } from 'react';

const NotFilled: FC = () => <span className={styles.notFilled}>Не заполнено</span>;

const InfoBlock = ({ title, content }: { title: string; content: string }) => {
  return (
    <div className={styles.infoContainer}>
      <span className={styles.infoTitle}>{title}</span>
      <span className={styles.infoValue}>{content ? content : <NotFilled />}</span>
    </div>
  );
};

type BioProp = {
  title: string;
  contentTitle?: string;
  content?: string | null;
};

const BioBlock: FC<BioProp> = ({ title, contentTitle, content }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.bioBlock}>
      <div className={styles.bioMain}>
        <span>{title}</span>
        <p>
          {content ? content : <NotFilled />}{' '}
        </p>
      </div>
      {!content && <CommentButton action={handleClickOpen} />}
      {content && (
            <TextButton propstyle={styles.showMore} onClick={handleClickOpen}>
              Показать полностью
            </TextButton>
          )}
      <Portal>
        <ModalEdit
          title={title}
          defaultValue={content}
          contentTite={contentTitle}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      </Portal>
    </div>
  );
};

interface IUserHeader {
  className?: string;
}

export const UserHeader: FC<IUserHeader> = ({ className }) => {
  const history = useHistory();

  const {
    first_name,
    last_name,
    date_of_birth,
    phone_number,
    location,
    email,
    pilot,
    bio,
    partners,
    achievements,
    photo
  } = useSelector((state: AppState) => state.userStore);

  const birthDate = formatDate(date_of_birth || '');

  const pilot_info = pilot &&
    pilot.pilot_team &&
    pilot.motorcycle_brand &&
    pilot.motorcycle_model && [
      { title: 'Команда', content: pilot.pilot_team },
      { title: 'Марка мотоцикла', content: pilot.motorcycle_brand },
      { title: 'Модель мотоцикла', content: pilot.motorcycle_model },
    ];

  const handleEditProfileClick = () => {
    history.push(AppRoutes.editUser);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.avatarBlock}>
        <AvatarCustom src={photo} />
        {email && <a href={`mailto: ${email}}`}>{email}</a>}
        {phone_number && <span>{phone_number}</span>}
        {location && <div className={styles.locationImg}><Flag/><span>{location}</span></div>}
      </div>
      <div className={styles.infoBlock}>
        <Avatar className={styles.avatarMobile} src={photo ? photo : undefined} />
        <div className={styles.title}>
          <h4>
            {`${first_name} ${last_name}`}{' '}
            <span className={styles.motoNumber}># {pilot && pilot.number}</span>
          </h4>
          <span className={styles.birthday}>
            Дата рождения: {date_of_birth ? birthDate : <NotFilled />}
          </span>
        </div>
        <Button
          onClick={handleEditProfileClick}
          styles={styles.button}
          variant="secondary">
          <Edit className={styles.buttonIcon} />
          Редактировать профиль
        </Button>
        <div className={styles.info}>
          {pilot_info &&
            pilot_info.map((item, index) => (
              <InfoBlock title={item.title} content={item.content} key={`pilot_info${index}`} />
            ))}
        </div>
        <div className={styles.bio}>
          <BioBlock title="Автобиография" contentTitle="О себе" content={bio} />
        </div>
        <div className={styles.bio2}>
          <BioBlock
            title="Достижения"
            contentTitle="Ваши спортивные достижения"
            content={achievements}
          />
          <BioBlock title="Партнеры" contentTitle="Ваши партнеры" content={partners} />
        </div>
      </div>
    </div>
  );
};
