import styles from './PhoneNumberForm.module.scss';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { formatPhoneNumber } from '../../../helpers';
import { ReactComponent as Error } from '../../../assets/icons/error.svg';
import { Button } from '../../../elements';
import NumberFormat from 'react-number-format';
import { AuthAPI } from '../../../api';
import { setPhoneNumber, setUserId } from '../../../app/reducers/user/user.actions';

interface IPhoneFormData {
  phone_number: string;
}

export const PhoneNumberForm: FC = () => {
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm<IPhoneFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (data: IPhoneFormData) => {
    if (!data || !data.phone_number) {
      setError(true);
      return;
    }
    const formattedPhone: string = formatPhoneNumber(data.phone_number);
    if (formattedPhone.length < 12) {
      setError(true);
      return;
    }
    AuthAPI.codeRequest(formattedPhone).then((data) => {
      dispatch(setUserId(data.id));
      dispatch(setPhoneNumber(formattedPhone));
    });
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <h2 className={styles.title}>Авторизация в систему</h2>

      <div className={styles.inputBlok}>
        <NumberFormat
          type="tel"
          placeholder="+7 (___) ___-__-__"
          className={`${styles.input} ${error && styles.error}`}
          format="+7 (###) ###-##-##"
          mask="_"
          {...register('phone_number')}
        />
        <label className={styles.label} htmlFor="phone">
          Номер телефона
        </label>
        {error && <Error className={styles.errorIcon} />}
      </div>

      <Button type="submit">Продолжить</Button>

      <p className={styles.text}>
        Нажимая продолжить вы подтверждаете, что ознакомились с нашей{' '}
        <a href="https://motorrika.ru/privacy" target="_blank">
          политикой конфиденциальности
        </a>{' '}
        и согласны на обработку персональных данных.
      </p>
    </form>
  );
};
