import * as yup from 'yup';

const maxDate = new Date('2012-01-01');
const minDate = new Date('1945-01-01');

yup.setLocale({
    mixed: {
        required: 'Обязательное поле',
        matches: 'Поле не может содержать только пробелы',
    },
    string: {
        min: 'Минимум ${min} символ(а)',
        max: 'Максимум ${max} символов',
        email: 'Введите корректный Email',
        matches: 'Поле не может содержать только пробелы',
    },
    date: {
        min: 'Минимально допустимый год: 1945',
        max: 'Максимально допустимый год: 2012',
        default: 'Введите корректную дату',
    },
});

export const editUserSchema = yup.object().shape({
    last_name: yup
        .string()
        .required()
        .min(2)
        .max(50)
        .matches(/^(?!\s+$).*/),
    first_name: yup
        .string()
        .required()
        .min(2)
        .max(50)
        .matches(/^(?!\s+$).*/),
    middle_name: yup
        .string()
        .required()
        .min(3)
        .max(50)
        .matches(/^(?!\s+$).*/),
    date_of_birth: yup
        .date()
        .required()
        .max(maxDate)
        .min(minDate)
        .typeError('Введите корректную дату'),
    email: yup.string().email().required().max(150),
    location: yup
        .string()
        .required()
        .matches(/^(?!\s+$).*/)
        .max(500),
    pilot_team: yup
        .string()
        .required()
        .matches(/^(?!\s+$).*/)
        .min(1)
        .max(200),
    motorcycle_brand: yup.object().required().typeError('Выберите марку мотоцикла'),
    motorcycle_model: yup
        .string()
        .required()
        .matches(/^(?!\s+$).*/),
    number: yup
        .number()
        .required()
        .positive('Число должно быть положительным')
        .integer('Поле должно быть числом')
        .typeError('Введите число'),
});

export const editUserPageSchema = yup.object().shape({
    last_name: yup
        .string()
        .min(2)
        .max(50)
        .matches(/^(?!\s+$).*/),
    first_name: yup
        .string()
        .min(2)
        .max(50)
        .matches(/^(?!\s+$).*/),
    middle_name: yup
        .string()
        .min(3)
        .max(50)
        .matches(/^(?!\s+$).*/),
    date_of_birth: yup.date().max(maxDate).min(minDate).typeError('Введите корректную дату'),
    email: yup.string().email().max(150).min(3),
    location: yup
        .string()
        .max(500)
        .matches(/^(?!\s+$).*/)
        .required(),
    pilot_team: yup
        .string()
        .min(1)
        .max(200)
        .matches(/^(?!\s+$).*/),
    motorcycle_brand: yup.object().nullable(),
    motorcycle_model: yup
        .string()
        .min(1)
        .matches(/^(?!\s+$).*/),
});

export const payCashless = yup.object().shape({
    inn: yup
        .string()
        .min(10, 'Количество символов: 10')
        .max(10, 'Количество символов: 10')
        .typeError('Введите число'),
    kpp: yup
        .string()
        .min(9, 'Количество символов: 9')
        .max(9, 'Количество символов: 9')
        .typeError('Введите число'),
    company_address: yup
        .string()
        .min(2)
        .max(100)
        .matches(/^(?!\s+$).*/),
    company_name: yup
        .string()
        .min(2)
        .max(100)
        .matches(/^(?!\s+$).*/),
});
