import { Autocomplete } from '@material-ui/lab';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { infoApi } from '../../api';
import { TextInput } from '../../hoc/TextInput';

interface ITeams {
  id?: number;
  name: string;
  created_at?: string;
}

type TeamSelectProps = {
  defaultValue?: string | null
}

export const TeamSelect: FC<TeamSelectProps> = ({defaultValue}) => {
  const [options, setOptions] = useState<ITeams[]>([]);
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    let unmounted = false;
    infoApi
      .getTeams()
      .then((teams) => {
        if (teams && !unmounted) {
          setOptions(teams);
        }
      })
      .catch((err) => {
        if (!unmounted) console.log(err);
      });
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <Controller
      name="pilot_team"
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value } }) => (
        <Autocomplete
        freeSolo
        autoSelect
        onChange={(event, val) => {
          if (val && typeof val === 'string') setValue('pilot_team', val);
          else if (val) setValue('pilot_team', val.name);
          else if (!val) setValue('pilot_team', '');
        }}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.name) {
            return option.name;
          }
          return '';
        }}
        value={value}
          renderInput={(params) => (
            <TextInput
              {...params}
              label="Название команды"
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              variant="filled"
              error={errors.pilot_team}
              helperText={errors?.pilot_team?.message}
            />
          )}
        />
      )}
    />
  );
};
