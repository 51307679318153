export interface IToken {
  access: string;
  refresh: string;
}

export enum tokens {
  access = 'access',
  refresh = 'refresh',
}

export const TokenApi = {
  setTokens(tokenData: IToken) {
    localStorage.setItem(tokens.access, tokenData.access);
    localStorage.setItem(tokens.refresh, tokenData.refresh);
  },
  setAccess(token: string) {
    localStorage.setItem(tokens.access, token)
  },
  getAccessToken() {
    return localStorage.getItem(tokens.access);
  },
  getRefreshToken() {
    return localStorage.getItem(tokens.refresh);
  },
  clearTokens() {
     localStorage.removeItem(tokens.access);
     localStorage.removeItem(tokens.refresh);
  },
};
