import { withStyles } from '@material-ui/core';
import { TextField, TextFieldProps } from '@material-ui/core';
import { forwardRef } from 'react';

const CustomTextField = withStyles({
  root: {
    width: '100%',
    border: 'none',
    '& .MuiFilledInput-root': {
      border: '1px solid #F6F7F9',
      borderRadius: '4px',
      backgroundColor: '#F6F7F9',
    },
    '& .MuiFilledInput-root.Mui-error': {
      border: '1px solid #D20000!important',
      borderRadius: '4px',
      backgroundColor: '#F6F7F9',
    },
    '& label': {
      color: '#7D8598',
      transform: 'translate(12px, 22px) scale(1)',
    },
    '& label.Mui-error': {
      color: '#7D8598',
    },
    '& label.Mui-focused': {
      color: '#7D8598',
    },
    '& .MuiFilledInput-root.Mui-focused': {
      border: '1px solid #7D8598',
      borderRadius: '4px',
    },
    '& .Mui-error': {
      marginLeft: 0,
      color: '#D20000',
    },
  },
})(TextField);

export const TextInput = forwardRef<any, TextFieldProps>((props, ref) => {
  return (
    <CustomTextField
      InputProps={{ disableUnderline: true }}
      {...props}
      variant="filled"
      ref={ref}
    />
  );
});
