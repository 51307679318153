import api from "./axios.main";


enum orderRoutes {
    currentOrder = "https://api.appmotoring.com/api/v1/users/current/orders/"
}

export const currentOrderApi = {
    getOrder() {
        return api.get(orderRoutes.currentOrder).then((res) => res.data);
    },
}
