import api from "./axios.main";


enum tiresRoutes {
  tires = "tires/",
  orders = "orders/",
}

export const tiresApi = {
  async getTires() {
    return api.get(tiresRoutes.tires).then((res) => res.data);
  },
  async getOrder(data: any) {
    return api.post(`${tiresRoutes.tires}${tiresRoutes.orders}`, data).then((res) => res.data);
  },
};
