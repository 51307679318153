import styles from './TireDescriptionModal.module.scss';
import { FC } from 'react';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '../../elements';
import { Tire } from '../../app/reducers/tires/tires.types';
import { useWindowWidth } from '../../hooks/useWindowWidth';

type Props = {
  handleClose: () => void;
  open: boolean;
  tire: Partial<Tire>;
};

export const TireDescriptionModal: FC<Props> = ({ open, handleClose, tire }) => {
  const { name, logo, price, width, profile, diameter, manufacturer, compound } = tire;
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;

  const priceBlock = (
    <div className={styles.price}>
      {new Intl.NumberFormat('ru-RU').format(Number(price || 0))}{' '}<span style={{ fontFamily: 'system-ui' }}>&#8381;</span>
    </div>
  );

  const tireDetailsBlock = (
    <div className={styles.tireDetails}>
      <div className={styles.leftBlock}>
        <div className={styles.detail}><div className={styles.label}>Ширина:</div><div className={styles.value}>{width}</div></div>
        <div className={styles.detail}><div className={styles.label}>Профиль:</div><div className={styles.value}>{profile}%</div></div>
        <div className={styles.detail}><div className={styles.label}>Диаметр:</div><div className={styles.value}>{diameter}’’</div></div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.detail}><div className={styles.label}>Производитель:</div><div className={styles.value} style={{ textTransform: 'uppercase' }}>{manufacturer}</div></div>
        <div className={styles.detail}><div className={styles.label}>Компаунд:</div><div className={styles.value}>{compound}</div></div>
      </div>
      <div className={styles.emptySpace}></div>
    </div>
  );

  const headerBlock = (
    <div className={styles.header}>
      <div className={styles.title}>Описание товара</div>
      <div className={styles.closeIcon} onClick={handleClose}><Close /></div>
    </div>
  );

  const nameBlock = (
    <div className={styles.tireName}>{name}</div>
  );

  const imageContainerBlock = (
    <div className={styles.imageContainer}><img src={logo} alt={logo} /></div>
  );

  const desktopBlock = (
    <>
      {headerBlock}
      <div className={styles.textContainer}>
        {imageContainerBlock}
        <div className={styles.body}>
          <div className={styles.tireDescription}>
            {
              nameBlock
            }
            {
              tireDetailsBlock
            }
          </div>
          {
            priceBlock
          }
        </div>
      </div>
    </>
  );

  const mobileBlock = (
    <>
      {headerBlock}
      <div className={styles.mobileContainer}>
        <div className={styles.mobileHead}>
          {imageContainerBlock}
          <div className={styles.mobileTitleBlock}>
            {nameBlock}
            {priceBlock}
          </div>
        </div>
        <div className={styles.mobileBody}>
          <div className={styles.tireDescription}>
            {
              tireDetailsBlock
            }
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Dialog
      onClose={handleClose}
      style={{ zIndex: 99999 }}
      aria-labelledby="customized-dialog-title"
      open={open}>
      {isMobile ? mobileBlock : desktopBlock}
      <div className={styles.footer}>
        <Button onClick={handleClose}>Закрыть</Button>
      </div>
    </Dialog>
  );
};
