import api from "./axios.main";


enum shopsRoutes {
  tires = "tires/",
  shops = "shops/",
}

export const shopsApi = {
  async getShops() {
    return api.get(`${shopsRoutes.tires}${shopsRoutes.shops}`).then((res) => res.data);
  },
};
