import {SubmmitedOrders} from "./order.reducer";

export const GET_ORDERS = 'GET_ORDERS';

interface IOrder {
    type: 'GET_ORDERS',
    order: SubmmitedOrders;
}

export type OrderActions =
    | IOrder
