import {
    GET_TIRES_REQUEST,
    GET_TIRES_SUCCESS,
    GET_TIRES_ERROR,
    TiresState,
    TiresActions,
    ADD_TIRE_TO_CART,
    REMOVE_TIRE_FROM_CART,
    INCREMENT_TIRE_IN_CART,
    DECREMENT_TIRE_IN_CART,
    CLEAR_TIRES_CART,
} from "./tires.types";

const initialState: TiresState = {
    tires: [],
    cart: [],
    isLoading: false,
    isLoaded: false,
    error: '',
}

export const TiresReducer = (state: TiresState = initialState, action: TiresActions) => {
    switch (action.type) {
        case GET_TIRES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_TIRES_SUCCESS:
            return {
                ...state,
                tires: [...action.tires],
                isLoading: false,
                isLoaded: true,
            };
        case GET_TIRES_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        case ADD_TIRE_TO_CART: {
            return {
                ...state,
                cart: [...state.cart, action.tire],
            };
        }
        case REMOVE_TIRE_FROM_CART: {
            const { tireId } = action;
            const tires = state.cart.filter(tire => tire.tire !== tireId);
            return {
                ...state,
                cart: [...tires],
            };
        };
        case INCREMENT_TIRE_IN_CART: {
            const tireId = action.tireId;
            const tires = state.cart.map(tireItem => tireItem.tire === tireId ? { ...tireItem, number: tireItem.number + 1 } : tireItem);
            return {
                ...state,
                cart: [...tires],
            };
        };
        case DECREMENT_TIRE_IN_CART: {
            const tireId = action.tireId;
            const tires = state.cart.map(tireItem => tireItem.tire === tireId ? { ...tireItem, number: tireItem.number - 1 } : tireItem);
            return {
                ...state,
                cart: [...tires],
            };
        };
        case CLEAR_TIRES_CART: {
            return {
                ...state,
                cart: [],
            };
        }
        default:
            return state;
    }
}
