import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { TiresCart } from '../components/TiresPage/TiresCart';
import { useWindowWidth } from '../hooks/useWindowWidth';
import { AppRoutes } from '../helpers';

export const TiresCartHOC: FC = () => {
  const displayWidth = useWindowWidth();
  const isTablet = displayWidth <= 912;
  const history = useHistory();

  if (!isTablet) {
    history.push(AppRoutes.main);
    return <></>;
  }
  return <TiresCart />
};
