import api from './axios.main';

enum infoRoutes {
  team = 'pilot-teams/',
  motoBrand = 'motorcycle-brands/',
  events = 'events/',
  results = 'results/',
}

export const infoApi = {
  getTeams() {
    return api.get(infoRoutes.team).then((res) => res.data);
  },
  getMotoBrands() {
    return api.get(infoRoutes.motoBrand).then((res) => res.data);
  },
  getEvents() {
    return api.get(infoRoutes.events).then((res) => res.data);
  },
  getResults() {
    return api.get(infoRoutes.results).then((res) => res.data);
  }
};
