import styles from './CheckboxGroup.module.scss';
import { ComponentPropsWithRef, FC } from 'react';
import { IEventDays } from '../../app/reducers/event/event.reducer';
import { formatDDMM } from '../../helpers';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import { IFormPhoto } from '../../components';

interface ICheckboxElement extends ComponentPropsWithRef<'input'> {
  propstyle?: string;
  title?: string;
  content: string;
  subcontent?: string;
}

const CheckboxElement: FC<ICheckboxElement> = ({
  title,
  content,
  subcontent,
  propstyle,
  ...other
}) => {
  return (
    <div className={`${styles.checkboxElement} ${propstyle || null}`}>
      {title && <span className={styles.boxTitle}>{title}:</span>}
      <label className={`${styles.checkbox} ${other.checked && styles.checked}`}>
        <input {...other} type="checkbox" />
        <span className={styles.checkboxContent}>{content}</span>
        {subcontent && <span className={styles.checkboxSubcontent}>{subcontent}</span>}
      </label>
    </div>
  );
};

interface ICheckboxGroup {
  day: IEventDays;
  index: number;
}

export const CheckboxGroup: FC<ICheckboxGroup> = ({ day, index }) => {
  const ddMM = formatDDMM(day.date);

  const form = useFormState();
  const isMainActive: boolean = form.values.photo
    ? form.values.photo.some((item: IFormPhoto) => item.event_day === day.id)
    : false;

  return (
    <div className={`${styles.container} ${isMainActive && styles.checked}`}>
      <Field name={`photo[${index}].event_day`} type="checkbox" parse={(v) => (v ? day.id : null)}>
        {(props) => <CheckboxElement {...props.input} title="Дата" content={ddMM} />}
      </Field>
      {day.photo_training_price && (
        <Field name={`photo[${index}].has_training_photo`} type="checkbox">
          {(props) => (
            <CheckboxElement
              {...props.input}
              disabled={!isMainActive}
              title="Время"
              content="Тренировочный день"
              subcontent={`≈${parseInt(day.photo_training_price)} ₽`}
            />
          )}
        </Field>
      )}
      {day.photo_race_price && (
        <Field name={`photo[${index}].has_race_photo`} type="checkbox">
          {(props) => (
            <CheckboxElement
              {...props.input}
              disabled={!isMainActive}
              content="Гонка"
              subcontent={`≈${parseInt(day.photo_race_price)} ₽`}
            />
          )}
        </Field>
      )}
    </div>
  );
};
