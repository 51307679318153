export const GET_TIRES_REQUEST = 'GET_TIRES_REQUEST';
export const GET_TIRES_SUCCESS = 'GET_TIRES_SUCCESS';
export const GET_TIRES_ERROR = 'GET_TIRES_ERROR';
export const ADD_TIRE_TO_CART = 'ADD_TIRE_TO_CART';
export const REMOVE_TIRE_FROM_CART = 'REMOVE_TIRE_FROM_CART';
export const INCREMENT_TIRE_IN_CART = 'INCREMENT_TIRE_IN_CART';
export const DECREMENT_TIRE_IN_CART = 'DECREMENT_TIRE_IN_CART';
export const CLEAR_TIRES_CART = 'CLEAR_TIRES_CART';

export interface MotorcycleModel {
    id: number,
    name: string,
    brand: string,
};

export interface Tire {
    compound: string,
    diameter: string,
    id: number,
    logo: string,
    manufacturer: string,
    motorcycle_models: Array<MotorcycleModel>,
    name: string,
    price: string,
    profile: number,
    width: number,
};

export interface TireInCart {
    tire: number,
    number: number,
};

export interface TiresState {
    tires: Array<Tire>,
    cart: Array<TireInCart>,
    isLoading: boolean,
    isLoaded: boolean,
    error: string,
};

interface ITiresRequest {
    type: typeof GET_TIRES_REQUEST,
};

interface ITiresSuccess {
    type: typeof GET_TIRES_SUCCESS,
    tires: Tire[],
};

interface ITiresError {
    type: typeof GET_TIRES_ERROR,
    error: string,
};

interface IAddTireToCart {
    type: typeof ADD_TIRE_TO_CART,
    tire: TireInCart,
};

interface IRemoveTireFromCart {
    type: typeof REMOVE_TIRE_FROM_CART,
    tireId: number,
};

interface IIncrementTireInCart {
    type: typeof INCREMENT_TIRE_IN_CART,
    tireId: number,
};

interface IDecrementTireInCart {
    type: typeof DECREMENT_TIRE_IN_CART,
    tireId: number,
};

interface IClearTiresCart {
    type: typeof CLEAR_TIRES_CART,
};

export type TiresActions =
    | ITiresRequest
    | ITiresSuccess
    | ITiresError
    | IAddTireToCart
    | IRemoveTireFromCart
    | IIncrementTireInCart
    | IDecrementTireInCart
    | IClearTiresCart;
