import { Dispatch } from '@reduxjs/toolkit';
import {
    GET_SHOPS_REQUEST,
    GET_SHOPS_SUCCESS,
    GET_SHOPS_ERROR,
} from "./shops.types";
import { shopsApi } from '../../../api';

export const getShopsRequest = () => ({
    type: GET_SHOPS_REQUEST,
});

export const getShopsSuccess = (shops: any[]) => ({
    type: GET_SHOPS_SUCCESS,
    shops,
});

export const getShopsError = (error: string) => ({
    type: GET_SHOPS_ERROR,
    error,
});

export const getShops = () => async (dispatch: Dispatch) => {
    dispatch(getShopsRequest());
    try {
        const shops: any[] = await shopsApi.getShops();
        dispatch(getShopsSuccess(shops));
    } catch (err) {
        dispatch(getShopsError(err.data));
    }
};
