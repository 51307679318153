import scss from './Success.module.scss';
import React, { FC, MouseEvent, useRef } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy_filled.svg';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Button } from '../Button/Button';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../helpers';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: '0 40px 0 40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '40px',
      paddingBottom: '28px',
      borderBottom: '1px solid #E9EBF2',
      [theme.breakpoints.down('xs')]: {
        margin: '10px 20px',
        padding: '5px',
        paddingBottom: '15px',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Success className={scss.logo} />
      <h3 style={{ textAlign: 'center' }}>{children}</h3>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: '28px 40px 16px 40px',
    backgroundColor: '#FAFAFB',
    borderRadius: '4px',
    padding: '12px!important',
    display: 'flex',
    border: '1px solid #E9EBF2',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 20px',
    },
  },
}))(MuiDialogContent);

type Props = {
  handleClose: () => void;
  open: boolean;
};

export const CustomizedDialogs: FC<Props> = ({ open, handleClose }) => {
  const history = useHistory();
  const handleGoOn = () => {
    history.push(AppRoutes.main);
  };
  const copy = useRef<HTMLDivElement>(null);

  const handleCopyText = (e: MouseEvent<HTMLButtonElement>) => {
    if (copy && copy.current) {
      navigator.clipboard.writeText(copy.current.innerText);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      style={{ zIndex: 99999 }}
      aria-labelledby="customized-dialog-title"
      open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Заявка на участие была успешно подана!
      </DialogTitle>
      <DialogContent>
        <div ref={copy} className={scss.textContainer}>
          <div className={scss.textTitleBlock}>
            <span>При себе необходимо иметь:</span>
            <Button type="button" onClick={handleCopyText} variant="secondary" styles={scss.button}>
              <Copy className={scss.copy} />
              Скопировать
            </Button>
          </div>
          <span>Всем:</span>
          <p>- спортивную страховку не менее чем на 300.000 руб. </p>
          <span>Участникам гонок:</span>
          <p>
            - справку из диспансера о допуске к соревнованиям.
            <br />- cпортивную лицензию.{' '}
          </p>
          <span>Командам:</span>
          <p>- каждый участник должен быть зарегистрирован индивидуально.</p>
        </div>
      </DialogContent>
      <div className={scss.partners}>
        {' '}
        <p>
          Наш страховой партнер: <a href="https://www.goprotect.ru/motoring">GoProtect</a>
        </p>
      </div>
      <div className={scss.footer}>
        <Button onClick={handleGoOn}>Продолжить</Button>
      </div>
    </Dialog>
  );
};
