import styles from './ErrorPaymentModal.module.scss';
import { FC } from 'react';
import { ReactComponent as Error } from '../../assets/icons/errorCheck.svg';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '../../elements';

type Props = {
  handleClose: () => void;
  open: boolean;
};

export const ErrorPaymentModal: FC<Props> = ({ open, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      style={{ zIndex: 99999 }}
      aria-labelledby="customized-dialog-title"
      open={open}>
        <div className={styles.textContainer}>
            <Error className={styles.logo} />
            <div className={styles.description}>
                <div>Что-то пошло не так..</div>
                <div>Оплата не была осуществлена.</div>
            </div>
        </div>
      <div className={styles.footer}>
        <Button onClick={handleClose}>Закрыть</Button>
      </div>
    </Dialog>
  );
};
