import { IUser } from './user.reducer';

export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const REMOVE_PHONE_NUMBER = 'REMOVE_PHONE_NUMBER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_USER_ID = 'SET_USER_ID';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const SET_IS_AUTH = 'SET_IS_AUTH';
export const AUTH_USER = 'AUTH_USER';
export const SET_LOADING = 'SET_LOADING';

interface ISetPhoneNumber {
  type: 'SET_PHONE_NUMBER';
  phone_number: string;
}

interface IRemovePhoneNumber {
  type: 'REMOVE_PHONE_NUMBER';
}

interface IClearUser {
  type: 'CLEAR_USER';
}

interface IAddUserId {
  type: 'SET_USER_ID';
  id: number;
}

interface IAuthUser {
  type: 'AUTH_USER';
  user: IUser;
}

interface ISetLoading {
  type: 'SET_LOADING';
  isLoading: boolean;
}

export type UserActions =
  | ISetPhoneNumber
  | IRemovePhoneNumber
  | IClearUser
  | IAddUserId
  | IAuthUser
  | ISetLoading;
