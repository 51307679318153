import { Authorization, Main } from './components';
import styles from './App.module.scss';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { ProtectedRoute } from './hoc/protected.route';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './app/reducers';
import { getUserFetch } from './app/reducers/user/user.actions';
import { CircularProgress, IconButton } from '@material-ui/core';
import { AppRoutes } from './helpers';
import { Alert } from '@material-ui/lab';
import { ReactComponent as CloseIcon } from './assets/icons/close.svg';
import { removeError } from './app/reducers/error/error.actions';
import ScrollTop from './elements/ScrollTop/ScrollTop';

const AlertNotify = () => {
  const { messages } = useSelector((state: AppState) => state.errorStore);
  const dispatch = useDispatch();

  const handleAlertClose = () => {
    dispatch(removeError());
  };
  useEffect(() => {
    const timeFade = setTimeout(() => {
      handleAlertClose();
    }, 1800);
    return () => {
      clearTimeout(timeFade);
    };
  }, [dispatch, messages]);
  return (
    <div
      key="errorHandler"
      className={messages && messages.length > 0 ? styles.allert : styles.allertNone}>
      <Alert
        severity="error"
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleAlertClose}>
            <CloseIcon />
          </IconButton>
        }>
        <div className={styles.alertInner}>
          {messages.map((message, index) => (
            <span key={`alert${index}`}>{message}</span>
          ))}
        </div>
      </Alert>
    </div>
  );
};

function App() {
  const dispatch = useDispatch();

  const { isAuthorized, isLoading } = useSelector((state: AppState) => state.userStore);

  useLayoutEffect(() => {
    dispatch(getUserFetch());
  }, [dispatch]);

  return (
    <div className={styles.app}>
      <div className={styles.container}>
        {isLoading ? (
          <CircularProgress className={styles.preloadCircular} />
        ) : (
          <>
            <ScrollTop />
            <Switch>
              <Redirect exact from="/" to={AppRoutes.main} />
              <Route path={AppRoutes.auth} component={Authorization} />
              <ProtectedRoute
                isAuthenticated={isAuthorized}
                authenticationPath={AppRoutes.auth}
                component={Main}
              />
              <Route>
                <h1>404</h1>
              </Route>
            </Switch>
          </>
        )}
      </div>
      <AlertNotify />
    </div>
  );
}

export default App;
