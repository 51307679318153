import { ComponentPropsWithRef, FC } from 'react';
import styles from './TextButton.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

export interface ITextButton extends ComponentPropsWithRef<'button'> {
  variant?: 'witharrow';
  propstyle?: string;
}

export const TextButton: FC<ITextButton> = (props) => {
  return (
    <button {...props} className={`${styles.button} ${props.propstyle && props.propstyle}`}>
      {props.variant === 'witharrow' && <Arrow className={styles.arrow} />}
      {props.children}
    </button>
  );
};
