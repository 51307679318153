import { FC } from 'react';
import { UserHeader, UpcomingEvents, SeasonResults } from '../../components';
import styles from './Main.module.scss';



export const MainPage: FC = () => {

  return (
    <div className={styles.main}>
      <UserHeader className={styles.header} />
      <UpcomingEvents className={styles.events} />
      <SeasonResults className={styles.results} />
    </div>
  );
};
