import React, { FC } from 'react';
import { suggestApi } from '../../api';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput } from '../../hoc/TextInput';
import { Autocomplete } from '@material-ui/lab';

type SelectAsyncProps = {
  defaultValue?: string | null;
};

export const SelectAsync: FC<SelectAsyncProps> = ({ defaultValue }) => {
  const [options, setOptions] = React.useState<string[]>([]);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value;
    try {
      const data = await suggestApi.postData(query);
      if (data && data.suggestions) {
        setOptions(data.suggestions);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Controller
      control={control}
      name="location"
      defaultValue={defaultValue}
      render={({ field: { value } }) => (
        <Autocomplete
          options={options}
          noOptionsText="Нет совпадений"
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.data.city) {
              return option.data.city;
            }
            return '';
          }}
          value={value}
          autoSelect
          onChange={(event, item) => {
            if (!item) setValue('location', '');
            else setValue('location', item.data.city);
          }}
          renderInput={(params) => (
            <TextInput
              {...params}
              label="Субъект РФ"
              onChange={handleChange}
              variant="filled"
              error={errors.location}
              helperText={errors?.location?.message}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
        />
      )}
    />
  );
};
