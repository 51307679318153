import styles from './ProfileForm.module.scss';
import { FC, useEffect } from 'react';
import { ReactComponent as Logo } from '../../assets/motoringLogo.svg';
import { Button, TextButton } from '../../elements';
import { UserApi, UserFetchData } from '../../api';
import { useDispatch } from 'react-redux';
import { authUser, clearUser } from '../../app/reducers/user/user.actions';
import { EditUser } from '../../elements/EditUser/EditUser';
import { editUserSchema } from '../../helpers/validation';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDDMMYYYY } from '../../helpers';

type formData = UserFetchData & { date_of_birth: Date; motorcycle_brand: any };

export const ProfileForm: FC = () => {
    const dispatch = useDispatch();

    const methods = useForm({ resolver: yupResolver(editUserSchema), reValidateMode: 'onBlur' });

    const {
        formState: { errors },
    } = methods;

    useEffect(() => {
        const firstErr = Object.values(errors)[0];
        if (firstErr && firstErr.ref && firstErr.ref.scrollIntoView)
            firstErr.ref.scrollIntoView({ block: 'center' });
    }, [errors]);

    const onSubmit = (values: formData) => {
        const parsedDate = formatDDMMYYYY(values.date_of_birth);
        const motorcycle_brand = values.motorcycle_brand.id;
        const userPut: UserFetchData = {
            ...values,
            date_of_birth: parsedDate,
            motorcycle_brand,
            number: +values.number,
        };
        UserApi.putUser(userPut)
            .then((resData) => {
                dispatch(authUser(resData));
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleBackButtonClick = () => {
        dispatch(clearUser());
    };

    return (
        <div className={styles.container}>
            <Logo className={styles.logo} />
            <FormProvider {...methods}>
                <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
                    <TextButton variant="witharrow" type="button" onClick={handleBackButtonClick}>
                        Назад
                    </TextButton>
                    <h2 className={styles.title}>Регистрация участника</h2>
                    <EditUser />
                    <Button type="submit" styles={styles.button}>
                        Продолжить
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
};
