import { combineReducers } from 'redux';
import { UserReducer } from './user/user.reducer';
import { ErrorState, ErrorReducer } from './error/error.reducer';
import { EventReducer } from './event/event.reducer';
import { UserState } from './user/user.reducer';
import { EventState } from './event/event.reducer';
import { OrderReducer, OrderState } from "./order/order.reducer";
import { TiresReducer } from "./tires/tires.reducer";
import { TiresState } from "./tires/tires.types";
import { ShopsReducer } from "./shops/shops.reducer";
import { ShopsState } from "./shops/shops.types";

export interface AppState {
  userStore: UserState;
  errorStore: ErrorState;
  eventStore: EventState;
  orderStore: OrderState;
  tiresStore: TiresState;
  shopsStore: ShopsState;
}

export const rootReducer = combineReducers({
  userStore: UserReducer,
  errorStore: ErrorReducer,
  eventStore: EventReducer,
  orderStore: OrderReducer,
  tiresStore: TiresReducer,
  shopsStore: ShopsReducer,
});
