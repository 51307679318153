import { ComponentPropsWithRef, FC } from 'react';
import styles from './Button.module.scss';

interface IButtonProps extends ComponentPropsWithRef<'button'> {
  variant?: 'default' | 'secondary' | 'success';
  styles?: string;
}

export const Button: FC<IButtonProps> = (props) => {
  const buttonTypeClassname = () => {
    if (props.variant === 'secondary') {
      return styles.secondary;
    } else if (props.variant === 'success') {
      return styles.success;
    }
    return styles.primary;
  };

  return (
    <button
      {...props}
      className={`${buttonTypeClassname()} ${
        props.styles && props.styles
      }`}>
      {props.children}
    </button>
  );
};
