import { TextButton } from '../TextButton/TextButton';
import { ReactComponent as Comment } from '../../assets/icons/comment.svg';
import { FC } from 'react';
import styles from './CommentButton.module.scss';

type Props = {
  action?: () => void;
};

export const CommentButton: FC<Props> = ({ action }) => {
  return (
    <div className={styles.commentButton}>
      <div className={styles.commentTextBlock}>
        <Comment className={styles.commentIcon} />
        <span>
          Об этом расскажет
          <br />
          комментатор
        </span>
      </div>
      <TextButton type="button" propstyle={styles.button} onClick={action}>
        Добавить
      </TextButton>
    </div>
  );
};
