import { Dispatch } from '@reduxjs/toolkit';
import { infoApi } from '../../../api';
import { IEvent } from './event.reducer';
import { REMOVE_EVENTS, SET_CURRENT_EVENT, SET_EVENTS, SET_IS_FETCHING } from './event.types';

export const setCurrentEvent = (id: number) => ({
  type: SET_CURRENT_EVENT,
  id,
});

export const setIsFetching = (value: boolean) => ({
  type: SET_IS_FETCHING,
  value,
});

export const setEvents = (events: IEvent[]) => ({
  type: SET_EVENTS,
  events,
})

export const removeEvents = () => ({
  type: REMOVE_EVENTS,
})

export const eventsFetch = () => async (dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
    try {
      const events: IEvent[] = await infoApi.getEvents();
      dispatch(setEvents(events));
      const initialEventId: number = events.filter(event => !event.has_order)[0]?.id || 0;
      dispatch(setCurrentEvent(initialEventId))
      dispatch(setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  dispatch(setIsFetching(false));
};