import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CLEAR_USER } from '../app/reducers/user/user.types';
import { store } from '../app/store';
import { TokenApi } from '../helpers';
import { AuthAPI } from './auth.api';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const AUTH_ERROR_CODE = 401;

const api = axios.create({
  baseURL: `${BASE_URL}api/v1/`,
  headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use(
  (req: AxiosRequestConfig) => {
    const token: string | null = TokenApi.getAccessToken();
    if (token) {
      req.headers['Authorization'] = `JWT ${token}`;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);

api.interceptors.response.use(
  (res: AxiosResponse) => res,
  async (err: AxiosError) => {
    console.dir(err);
    const status = err.response ? err.response.status : null;
    if (status && status === AUTH_ERROR_CODE) {
      const token: string | null = TokenApi.getRefreshToken() || null;
      if (token) {
        AuthAPI.refreshToken(token)
          .then((data) => {
            const { access } = data;
            TokenApi.setAccess(access);
            return api(err.config);
          })
          .catch((err) => {
            TokenApi.clearTokens();
            store.dispatch({
              type: CLEAR_USER,
            });
            return Promise.reject(err);
          });
      } else {
        TokenApi.clearTokens();
        store.dispatch({
          type: CLEAR_USER,
        });
        return Promise.reject(err);
      }
    }
    if (err.response && err.response.data) {
      if (!err.response.data.messages) {
        const errorArrays: string[] = Object.values(err.response.data);
        console.log(errorArrays);
        const messages: string[] = errorArrays.flat();
        store.dispatch({
          type: 'SET_ERROR',
          messages,
        });
        Promise.reject(err);
      }
    }
  },
);

export default api;
