import styles from './ModalEdit.module.scss';
import { FC, useEffect, useRef } from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
import { UserApi } from '../../api';
import { useHistory } from 'react-router-dom';

interface IModalEdit {
  title: string;
  contentTite?: string;
  defaultValue?: string | null;
  isOpen: boolean;
  handleClose: () => void;
}

const fieldNames: { [key: string]: string } = {
  Автобиография: 'bio',
  Партнеры: 'partners',
  Достижения: 'achievements',
};

export const ModalEdit: FC<IModalEdit> = ({
  title,
  contentTite,
  isOpen,
  handleClose,
  defaultValue,
}) => {
  const descriptionElementRef = useRef<HTMLTextAreaElement>(null);
  const formElementRef = useRef<HTMLDivElement>(null);

  const history = useHistory()

  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  useOnClickOutside(formElementRef, handleClose);

  const onSubmit = async (data: any) => {
    try {
      const newUser = await UserApi.patchUser({
        [fieldNames[title]]: data[fieldNames[title]] || '',
      });
      if (newUser) {
        history.go(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!isOpen) return null;
  return (
    <div className={styles.modalBackground}>
      <div ref={formElementRef} className={styles.modalContainer}>
        <button type="button" onClick={handleClose} className={styles.closeButton}>
          <Close className={styles.closeImg} />
        </button>
        <h2 className={styles.modalTitle}>{title}</h2>
        <Form onSubmit={onSubmit}>
          {(props) => (
            <form className={styles.form} onSubmit={props.handleSubmit}>
              <div className={styles.dialogContainer}>
                <label className={styles.subtitle}>{contentTite}</label>
                <Field
                  defaultValue={defaultValue}
                  name={fieldNames[title]}
                  component="textarea"
                  ref={descriptionElementRef}
                  className={styles.textInput}
                  allowNull
                />
              </div>
              <div className={styles.buttonsGroup}>
                <Button variant="secondary" onClick={handleClose}>
                  Отмена
                </Button>
                <Button type="submit">Сохранить</Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
