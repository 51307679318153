import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { Tire } from '../../app/reducers/tires/tires.types';
import { Button } from '../../elements';
import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { addTireToCart, removeTireFromCart } from '../../app/reducers/tires/tires.actions';

import styles from './TireComponent.module.scss';

interface TireProps extends Tire {
    isInCart: boolean;
}

export const TireComponent: FC<TireProps> = ({
    id,
    compound,
    diameter,
    logo,
    manufacturer,
    name,
    price,
    profile,
    width,
    isInCart,
}) => {
    const dispatch = useDispatch();
    const displayWidth = useWindowWidth();
    const isMobile = displayWidth <= 600;

    const handleAddToCart = () => {
        const params = {
            tire: id,
            number: 1,
        };

        dispatch(addTireToCart(params));
    };

    const handleRemoveFromCart = () => {
        dispatch(removeTireFromCart(id));
    };

    const detailsBlock = (
        <div className={styles.detailsBlock}>
            <div className={styles.leftBlock}>
                <div className={styles.detail}><div className={styles.label}>Ширина:</div><div className={styles.value}>{width}</div></div>
                <div className={styles.detail}><div className={styles.label}>Профиль:</div><div className={styles.value}>{profile}%</div></div>
                <div className={styles.detail}><div className={styles.label}>Диаметр:</div><div className={styles.value}>{diameter}’’</div></div>
            </div>
            <div className={styles.rightBlock}>
                <div className={styles.detail}><div className={styles.label}>Производитель:</div><div className={styles.value} style={{ textTransform: 'uppercase' }}>{manufacturer}</div></div>
                <div className={styles.detail}><div className={styles.label}>Компаунд:</div><div className={styles.value}>{compound}</div></div>
            </div>
        </div>
    );

    const actionBlock = (
        <div className={styles.actionBlock}>
            <div className={styles.price}>
                {new Intl.NumberFormat('ru-RU').format(Number(price))}{' '}<span style={{ fontFamily: 'system-ui' }}>&#8381;</span>
            </div>
            <div className={styles.actions}>
                {
                    !isInCart ? (
                        <>
                            <Button styles={styles.button} onClick={handleAddToCart}><Lock className={styles.buttonIcon} />В корзину</Button>
                        </>
                    ) : (
                        <>
                            <Button styles={styles.button} onClick={handleRemoveFromCart} variant="success"><Check className={styles.buttonCheckIcon} />Добавлено</Button>
                        </>
                    )

                }
            </div>
        </div>
    )

    return (
        <div className={styles.container}>
            <div className={styles.mediaBlock}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={logo} alt={name} />
                </div>
                <div className={styles.descriptionBlock}>
                    <div className={styles.title}>
                        {name}
                    </div>
                    {isMobile ? actionBlock : detailsBlock}
                </div>
            </div>
            {isMobile ? detailsBlock : actionBlock}
        </div>
    );
};
