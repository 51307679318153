import { Dispatch } from 'redux';
import { UserApi } from '../../../api';
import { TokenApi } from '../../../helpers';
import { IUser } from './user.reducer';
import {
  SET_PHONE_NUMBER,
  REMOVE_PHONE_NUMBER,
  CLEAR_USER,
  SET_USER_ID,
  AUTH_USER,
  SET_LOADING,
} from './user.types';

export const setPhoneNumber = (phone_number: string) => ({
  type: SET_PHONE_NUMBER,
  phone_number,
});

export const removePhoneNumber = () => ({
  type: REMOVE_PHONE_NUMBER,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

export const setUserId = (id: number) => ({
  type: SET_USER_ID,
  id,
});

export const authUser = (user: IUser) => ({
  type: AUTH_USER,
  user,
});

export const setLoading = (isLoading: boolean) => ({
  type: SET_LOADING,
  isLoading,
});

export const getUserFetch = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  const token = TokenApi.getAccessToken() || '';
  if (token) {
    try {
      const user = await UserApi.getUser();
      dispatch(authUser(user));
      dispatch(setLoading(false));
    } catch (err) {
      console.dir(err);
      if (
        err &&
        err.response &&
        err.response.status === 401 &&
        err.response.data.code === 'user_not_found'
      ) {
        TokenApi.clearTokens();
        dispatch(clearUser());
      }
    }
  }
  dispatch(setLoading(false));
};
