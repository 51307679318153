export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

interface ISetError {
  type: 'SET_ERROR';
  messages: string[];
}

interface IRemoveError {
  type: 'REMOVE_ERROR';
}

export type ErrorActions = ISetError | IRemoveError;
