import { Autocomplete } from '@material-ui/lab';
import { useLayoutEffect } from 'react';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { infoApi } from '../../api';
import { TextInput } from '../../hoc/TextInput';

export interface IMotoModels {
  id?: number;
  name: string;
}

export interface IMotoBrands {
  id?: number;
  name: string;
  models: IMotoModels[];
}

type MotoBrandProps = {
  defaultValue?: string | null;
};

export const MotoBrand: FC<MotoBrandProps> = ({ defaultValue }) => {
  const [options, setOptions] = useState<IMotoBrands[]>([]);
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  useLayoutEffect(() => {
    let unmounted = false;
    infoApi
      .getMotoBrands()
      .then((data: IMotoBrands[]) => {
        if (data && !unmounted) {
          setOptions(data);
        }
      })
      .catch((err) => console.log(err));
    return () => {
      unmounted = true;
    };
  }, []);
  return (
    <Controller
      name="motorcycle_brand"
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value } }) => (
        <Autocomplete
          noOptionsText="Нет совпадений"
          options={options}
          value={value}
          autoSelect
          onChange={(event, newValue) => {
            if (!newValue) setValue('motorcycle_brand', null);
            else setValue('motorcycle_brand', newValue);
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.name) {
              return option.name;
            }
            return '';
          }}
          getOptionSelected={(option, value) => option === value}
          renderInput={(params) => (
            <TextInput
              {...params}
              label="Марка мотоцикла"
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              variant="filled"
              error={errors.motorcycle_brand}
              helperText={errors?.motorcycle_brand?.message}
            />
          )}
        />
      )}
    />
  );
};
