import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

export type IProtectedRoute = {
  isAuthenticated: boolean;
  authenticationPath: string;
} & RouteProps;

export const ProtectedRoute: FC<IProtectedRoute> = ({isAuthenticated, authenticationPath, ...routeProps}) => {
  if(isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
};