import styles from './ModalWindowPayment.module.scss';
import React, {FC, MouseEvent, useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../helpers';
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import {Field, Form} from "react-final-form";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {Button, RadioButton} from "..";
import {ReactComponent as Cash} from "../../assets/icons/cash.svg";
import {ReactComponent as SpbPay} from "../../assets/icons/spb_pay.svg";
import {ReactComponent as Writing} from "../../assets/icons/writing.svg";

type Props = {
  handleClose: () => void;
  open: boolean;
  invoiceLink: string | undefined;
  idOrder: number | undefined | null;
};

export enum PaymentTypes {
  onSpot = 'onSpot',
  cashless = 'cashless'
}

export interface IOrderFormData {
  paymentType: string;
}

export const ModalWindowPayment: FC<Props> = ({ open, handleClose, invoiceLink, idOrder }) => {
  const history = useHistory();
  const handleGoOn = () => {
    history.push(AppRoutes.orders);
  };
  const descriptionElementRef = useRef<HTMLTextAreaElement>(null);

  const formElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  useOnClickOutside(formElementRef, handleClose);

  const onSubmit = (values: IOrderFormData) => {
    if(values.paymentType === 'onSpot') window.open(`${invoiceLink}`, '_blank');
    if(values.paymentType === 'cashless') window.open(AppRoutes.cashless + `?idOrder=${idOrder}`)
  }

  const initialFormValues: IOrderFormData = {
    paymentType: PaymentTypes.onSpot,
  };

  if (!open) return null;
  return (
    <div className={styles.modalBackground}>
      <div ref={formElementRef} className={styles.modalContainer}>
        <button type="button" onClick={handleClose} className={styles.closeButton}>
          <Close className={styles.closeImg} />
        </button>
        <h2 className={styles.modalTitle}>Выбор способа оплаты</h2>
        <Form
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          render={({handleSubmit, form}) => (
            <form className={styles.participationContainer} onSubmit={handleSubmit}>
              <Field type="radio" value={PaymentTypes.onSpot} name="paymentType">
                {(props) => (
                  <RadioButton {...props.input} propStyles={styles.radio}>
                    <SpbPay />
                    <span>Оплата по СБП</span>
                  </RadioButton>
                )}
              </Field>
              <Field type="radio" value={PaymentTypes.cashless} name="paymentType">
                {(props) => (
                  <RadioButton {...props.input} propStyles={styles.radio}>
                    <Writing />
                    <span>Безналом для юр. лиц</span>
                  </RadioButton>
                )}
              </Field>

              <div className={styles.buttons}>
                <Button type="reset" onClick={handleClose} styles={styles.button} variant="secondary">
                  Закрыть
                </Button>
                <Button type="submit" styles={styles.button}>
                  Продолжить
                </Button>
              </div>
            </form>
            )}/>
      </div>
    </div>
  );
};
