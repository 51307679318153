import { ChangeEvent, FC } from 'react';
import styles from './Avatar.module.scss';
import { ReactComponent as Camera } from '../../assets/icons/camera.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { AxiosResponse } from 'axios';
import api from '../../api/axios.main';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError } from '../../app/reducers/error/error.actions';
import Compressor from 'compressorjs';

interface IAvatarProps {
  src?: string | null;
  propstyle?: string;
}

export const Avatar: FC<IAvatarProps> = ({ src, propstyle }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const MAX_IMG_SIZE = 6291456;

    const form = new FormData();
    const img = e.currentTarget.files && e.currentTarget.files[0];
    if (img && img.size <= MAX_IMG_SIZE) {
      const MAX_SERVER_SIZE = 1048576;
      new Compressor(img, {
        convertSize: MAX_SERVER_SIZE,
        quality: 0.6,
        width: 1024,
        height: 1024,
        success(result) {
          console.log(result)
          form.append('photo', result, img.name);
          api
            .patch('users/current/', form, {
              headers: {
                accept: 'application/json',
                'Content-Type':
                  'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW;',
              },
            })
            .then((res: AxiosResponse) => {
              if (res && res.status >= 200 && res.status < 300) {
                history.go(0);
              }
            });
        },
      });
    }
    if (img && img.size > MAX_IMG_SIZE) {
      dispatch(setError(['Размер изоображения превышает допустимый']));
    }
  };

  const handleDeletePhoto = async () => {
    const formData = new FormData();
    formData.append('photo', '');
    const res = await api.patch('users/current/', formData, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW;',
      },
    });
    if (res) {
      history.go(0);
    }
  };

  return (
    <div className={`${styles.avatarWrapper} ${propstyle && propstyle}`}>
      {src ? (
        <div className={styles.inputWrapper}>
          <div className={styles.userImage} style={{ backgroundImage: `url(${src}` }}></div>
          {/* <img className={styles.userImage} src={src} alt="User avatar" /> */}
          <div className={styles.buttonsHover}>
            <label>
              <input type="button" onClick={handleDeletePhoto} />
              <Delete className={styles.buttonIcon} />
              Удалить
            </label>
            <label>
              <input type="file" accept="image/jpeg,image/png" onChange={onUploadFile} />
              <Edit className={styles.buttonIcon} />
              Изменить
            </label>
          </div>
        </div>
      ) : (
        <div className={styles.inputWrapper}>
          <label className={styles.inputLabel}>
            <Camera className={styles.cameraIcon} />
            Загрузить
            <br />
            фотографию
            <input type="file" accept="image/jpeg,image/png" onChange={onUploadFile} />
          </label>
        </div>
      )}
    </div>
  );
};
