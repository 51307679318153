import { Dispatch } from '@reduxjs/toolkit';
import {
    GET_TIRES_REQUEST,
    GET_TIRES_SUCCESS,
    GET_TIRES_ERROR,
    Tire,
    ADD_TIRE_TO_CART,
    REMOVE_TIRE_FROM_CART,
    INCREMENT_TIRE_IN_CART,
    DECREMENT_TIRE_IN_CART,
    TireInCart,
    CLEAR_TIRES_CART,
} from "./tires.types";
import { tiresApi } from '../../../api';

export const getTiresRequest = () => ({
    type: GET_TIRES_REQUEST,
});

export const getTiresSuccess = (tires: Tire[]) => ({
    type: GET_TIRES_SUCCESS,
    tires,
});

export const getTiresError = (error: string) => ({
    type: GET_TIRES_ERROR,
    error,
});

export const getTires = () => async (dispatch: Dispatch) => {
    dispatch(getTiresRequest());
    try {
        const tires: Tire[] = await tiresApi.getTires();
        dispatch(getTiresSuccess(tires));
    } catch (err) {
        dispatch(getTiresError(err.data));
    }
};

export const addTireToCart = (tire: TireInCart) => ({
    type: ADD_TIRE_TO_CART,
    tire,
});

export const removeTireFromCart = (tireId: number) => ({
    type: REMOVE_TIRE_FROM_CART,
    tireId,
});

export const incrementTireInCart = (tireId: number) => ({
    type: INCREMENT_TIRE_IN_CART,
    tireId,
});

export const decrementTireInCart = (tireId: number) => ({
    type: DECREMENT_TIRE_IN_CART,
    tireId,
});

export const clearTiresCart = () => ({
    type: CLEAR_TIRES_CART,
});
