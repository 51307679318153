import { TokenApi } from '../../../helpers';
import {
  UserActions,
  REMOVE_PHONE_NUMBER,
  SET_PHONE_NUMBER,
  CLEAR_USER,
  SET_USER_ID,
  AUTH_USER,
  SET_LOADING,
} from './user.types';

export interface IPilot {
  id: number | null;
  pilot_team: string | null;
  motorcycle_brand: string | null;
  motorcycle_model: string | null;
  number: string | null;
}

export interface IUser {
  id?: number | null;
  phone_number?: string | null;
  email?: string | null;
  first_name?: string | null;
  is_profile_exist?: boolean;
  last_name?: string | null;
  middle_name?: string | null;
  pilot?: null | IPilot;
  date_of_birth?: string | null,
  location?: string | null,
  achievements?: string | null,
  bio?: string | null,
  partners?: string | null,
  motorcycle_brand?: number | null,
  pilot_team?: string | null,
  motorcycle_model?: string | null,
  number?: number | null,
  photo?: string | null,
  company_address?: string | null,
  company_name?: string | null,
  inn?: string | null,
  kpp?: string | null,
}

export type UserState = {
  isAuthorized: boolean;
  isLoading: boolean,
} & IUser;

const initialState: UserState = {
  isAuthorized: TokenApi.getAccessToken() ? true : false,
  isLoading: false,
};

export const UserReducer = (state: UserState = initialState, action: UserActions) => {
  switch (action.type) {
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phone_number: action.phone_number,
      };
    case REMOVE_PHONE_NUMBER:
      return {
        ...state,
        phone_number: null
      };
    case CLEAR_USER:
      TokenApi.clearTokens();
      return {
        ...initialState,
        isAuthorized: false,
      };
    case SET_USER_ID:
      return {
        ...state,
        id: action.id
      };
    case AUTH_USER:
      return {
        ...state,
        ...action.user,
        isAuthorized: true,
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    default:
      return state;
  }
};
