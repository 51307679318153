import styles from './RadioButton.module.scss';
import { ComponentPropsWithRef, FC } from 'react';

interface IRadioButton extends ComponentPropsWithRef<'input'> {
  propStyles?: string;
  component?: any;
}

export const RadioButton: FC<IRadioButton> = ({ propStyles, component, children, ...other }) => {
  return (
    <div style={{position: 'relative'}}>
      <label className={`${styles.radio} ${propStyles || null} ${other.checked && styles.checked}`}>
        <input {...other} type="radio" className={styles.input} />
        {children}
      </label>
      {
        component && component
      }
    </div>
  );
};
