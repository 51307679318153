import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentEvent } from '../../app/reducers/event/event.actions';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import { AppRoutes, formatDDMM } from '../../helpers';
import styles from './EventButton.module.scss';

export type EventProps = {
  location: string;
  name: string;
  start_date: string;
  end_date: string;
  logo: string;
  id: number;
  has_order: boolean;
};

export const EventButton: FC<EventProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const startDate = formatDDMM(props.start_date);
  const endDate = formatDDMM(props.end_date);

  const handleEventButtonClick = () => {
    dispatch(setCurrentEvent(props.id));
    history.push(AppRoutes.event);
  };

  return (
      <button
        disabled={props.has_order}
        type="button"
        onClick={handleEventButtonClick}
        className={styles.container}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={props.logo} alt={`Event ${props.name}`} />
        </div>
        <div className={styles.titleContainer}>
          <h3>{props.location}</h3>
          <p>{props.name}</p>
        </div>
        <span className={styles.date}>
          {startDate} - {endDate}
        </span>
        <Arrow className={styles.arrow} />
      </button>
  );
};
