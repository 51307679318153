import {
    GET_SHOPS_REQUEST,
    GET_SHOPS_SUCCESS,
    GET_SHOPS_ERROR,
    ShopsActions,
    ShopsState,
} from "./shops.types";

const initialState: ShopsState = {
    shops: [],
    isLoading: false,
    isLoaded: false,
    error: '',
}

export const ShopsReducer = (state: ShopsState = initialState, action: ShopsActions) => {
    switch (action.type) {
        case GET_SHOPS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_SHOPS_SUCCESS:
            return {
                ...state,
                shops: [...action.shops],
                isLoading: false,
                isLoaded: true,
            };
        case GET_SHOPS_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
