export const GET_SHOPS_REQUEST = 'GET_SHOPS_REQUEST';
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS';
export const GET_SHOPS_ERROR = 'GET_SHOPS_ERROR';

export interface ShopsState {
    shops: Array<any>,
    isLoading: boolean,
    isLoaded: boolean,
    error: string,
};

interface IShopsRequest {
    type: typeof GET_SHOPS_REQUEST,
};

interface IShopsSuccess {
    type: typeof GET_SHOPS_SUCCESS,
    shops: any[],
};

interface IShopsError {
    type: typeof GET_SHOPS_ERROR,
    error: string,
};

export type ShopsActions =
    | IShopsRequest
    | IShopsSuccess
    | IShopsError;
