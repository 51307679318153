import styles from "./Applications.module.scss"
import React, {FC, useState} from "react";
import check from '../../../assets/icons/check_24px.svg'
import card from '../../../assets/icons/card.svg'
import {AppRoutes, formatDate, formatDDMM} from "../../../helpers";
import { ModalWindowPayment } from "../../../elements/ModalWindowPayment/ModalWindowPayment";
import { useHistory } from 'react-router-dom';


export interface SubOrders {
    index: number;
    id?: number | null;
    event_name?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    status?: string | null;
    event_location?: string | null;
    logo?: any | null;
    event_order_group: event_order_group
    payment_info?: string;
    invoice_link?: string;
}

export interface event_order_group {
    pilot_common_class?: string | null;
    pilot_special_class?: string | null;
    price: number | bigint;
    additional_services: additional_services[];
    events: events[];
}

export interface additional_services {
    id?: number | null;
    name?: string | null;
}

export interface events {
    date?: string | null;
    has_training_photo?: boolean | null;
    has_race_photo?: boolean | null;
    price?: number | null;
}

const Applications: FC<SubOrders> = (props) => {
  const {events} = props.event_order_group;
  const {additional_services} = props.event_order_group;

  let [modalWindow, setModalWindow] = useState<boolean>(false);
  const history = useHistory();

  const handleClose = () => {
    history.push(AppRoutes.orders);
    setModalWindow(false);
  };

    return (
        <div className={styles.cartContainer}>
            <div>
                <div className={styles.titleContainer}>
                    <div className={styles.titleInfo}>
                        <img src={props.logo} className={styles.logo}/>
                        <div className={styles.imgDescr}>
                            <div className={styles.eventLocation}>{props.event_location}</div>
                            <div className={styles.eventName}>{props.event_name}</div>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                            {props.invoice_link ?
                                <div className={styles.payButton}><span>{props.payment_info}</span><div className={styles.pay} onClick={() => setModalWindow(true)} ><img src={card}/>Оплатить</div></div>
                                : <div className={styles.paidButton}>
                                    {props.payment_info === 'Оплачено' ?
                                      <span className={styles.completely}><img src={check}/>{props.payment_info}</span>
                                      : <span className={styles.partially}>{props.payment_info}</span>}
                                </div>
                            }
                    </div>
                </div>
                <div>
                    {events.map((ev, index) =>
                        <div className={styles.blockBox}>
                            <div className={styles.leftContent}>
                                <div className={styles.indexBox}>{index + 1}</div>
                                <div className={styles.dateContainer}>
                                    <div className={styles.dateTitle}>Дата</div>
                                    <div className={styles.dateContent}>{
                                        //@ts-ignore
                                        formatDDMM(ev.date)}</div>
                                </div>
                            </div>
                            <div className={styles.priceBlock}>{
                                // @ts-ignore
                                new Intl.NumberFormat('ru-RU').format(ev.price) + ' ₽'} </div>
                        </div>
                    )}
                    <div className={styles.blockBox}>
                        <div className={styles.leftContent}>
                            <div className={styles.indexBox}>{events.length + 1}</div>
                            <div className={styles.dateContainer}>
                                <div className={styles.dateTitle}>Класс гонки</div>
                                <div className={styles.dateContent}>
                                    {props.event_order_group.pilot_common_class ||
                                    props.event_order_group.pilot_special_class}</div>
                            </div>
                        </div>
                    </div>

                    {events.map((ev, index) =>
                        ev.has_training_photo || ev.has_race_photo ?
                            <div className={styles.blockBox}>
                                <div className={styles.leftContent}>
                                    <div className={styles.indexBox}>{index + 4}</div>
                                    <div className={styles.dateContainer}>
                                        <div className={styles.dateTitle}>Фотосессия</div>
                                        <div className={styles.dateContent}>
                                            {   //@ts-ignore
                                                formatDDMM(ev.date)}
                                            {ev.has_training_photo && ev.has_race_photo
                                                ? <span>{' (тренировка, гонка)'}</span>
                                                : ev.has_training_photo
                                                    ? <span> (тренировка)</span>
                                                    : ev.has_race_photo
                                                        ? <span> (гонка) </span>
                                                        : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.pricePhotoBlock}> Оплата на <br/> месте</div>
                            </div> : null
                    )}
                </div>

                {additional_services.map((ev, index) =>
                    ev.name ?
                        <div className={styles.blockBox}>
                            <div className={styles.leftContent}>
                                <div className={styles.indexBox}>{events.length + 4}</div>
                                <div className={styles.dateContainer}>
                                    <div className={styles.dateTitle}>Услуги</div>
                                    <div className={styles.dateContent}>
                                        {ev.name}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.pricePhotoBlock}> Оплата на <br/> месте</div>
                        </div> : null
                )}

                <div className={styles.totalPrice}>
                    <div className={styles.titlePrice}>Итоговая стоимость:</div>
                    <div
                        className={styles.totalTitlePrice}>{new Intl.NumberFormat('ru-RU').format(props.event_order_group.price) + ' ₽'}</div>
                </div>
            </div>
          <ModalWindowPayment handleClose={handleClose} invoiceLink={props.invoice_link} idOrder={props.id} open={modalWindow}/>
        </div>

    );
};

export default Applications;
