import { ErrorActions, REMOVE_ERROR, SET_ERROR } from './error.types';

export type ErrorState = {
  messages: string[];
};

const initialState: ErrorState = {
  messages: [],
};

export const ErrorReducer = (state: ErrorState = initialState, action: ErrorActions) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        messages: action.messages
      };
    case REMOVE_ERROR:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};
